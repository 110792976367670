import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AirtableProductCard from './AirtableProductCard';
import { connect } from 'react-redux';
import InputBox from '../../../reusbleComponents/InputBox';
import { FaSearch } from 'react-icons/fa';
import { FETCH_URL } from '../../../constants';

const UnconnectedAirtableProducts = ({ project, exchangeRate }) => {
  const [airtableProducts, setAirtableProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [ignoreCur, setIgnoreCur] = useState(false);

  useEffect(() => {
    const getAirtableProducts = async () => {
      setLoading(true);

      let projectCur = project.projectCurrency === 'CAD' ? 'CAD' : 'USD';
      let params = {
        ignoreCur: ignoreCur,
        projectCurrency: projectCur,
      };

      let response = await fetch(`${FETCH_URL}/airtable/products/getAll`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);

      if (payload.success) {
        setLoading(false);
        setAirtableProducts(payload.data);
      } else {
        setLoading(false);
      }
    };
    if (project) {
      getAirtableProducts();
    }
  }, [ignoreCur]);

  const searchHandler = async (evt) => {
    if (searchWord.trim().length !== 0) {
      setLoading(true);
      let data = {
        projectCurrency: project.projectCurrency,
        ignoreCur: ignoreCur,
      };
      let response = await fetch(
        `${FETCH_URL}/airtable/products/${searchWord.trim()}`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);

      if (payload.success) {
        setLoading(false);
        setAirtableProducts(payload.data);
      } else {
        setLoading(false);
      }
    }
  };

  const handleIgnoreCurrency = () => {
    setIgnoreCur(!ignoreCur);
  };

  return (
    <>
      <div className='product-airtable-container'>
        <div className='product-airtable-search-container'>
          {!loading && (
            <>
              <div className='product-airtable-currency-dropDown'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ignoreCur}
                      onChange={handleIgnoreCurrency}
                      name='checkedCur'
                      color='primary'
                    />
                  }
                  label='Ignore Currency'
                />
              </div>
              <div className='product-airtable-search'>
                <InputBox
                  background=' #FFFFFF'
                  border='1px solid #B8C5D3'
                  borderRadius='40px 0 0 40px'
                  width='100%'
                  height='30px'
                  fontSize='12px'
                  padding='5px 10px'
                  placeholder='Search'
                  onChange={(evt) => {
                    setSearchWord(evt.target.value);
                  }}
                />
                <button
                  onClick={searchHandler}
                  className='product-airtable-search-button'
                >
                  <FaSearch />
                </button>
              </div>
              <div className='product-airtable-count'>
                {airtableProducts && `Items: ${airtableProducts.length}`}
              </div>
            </>
          )}
        </div>
        <div
          style={{
            height: '95%',
            position: 'relative',
            overflowY: 'scroll',
          }}
        >
          <div className='product-card-container'>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {airtableProducts &&
                  !filteredProducts &&
                  airtableProducts.map((product) => (
                    <AirtableProductCard
                      item={product}
                      exchangeRate={exchangeRate}
                      projectCurrency={project.projectCurrency}
                    />
                  ))}
                {filteredProducts &&
                  filteredProducts.map((product) => (
                    <AirtableProductCard
                      item={product}
                      exchangeRate={exchangeRate}
                      projectCurrency={project.projectCurrency}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
let mapStateToProps = (state) => {
  return {
    exchangeRate: state.exchangeRate,
    project: state.project,
  };
};
let AirtableProducts = connect(mapStateToProps)(UnconnectedAirtableProducts);

export default AirtableProducts;
