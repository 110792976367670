import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Fade from '@material-ui/core/Fade';

import { FaCheckCircle } from 'react-icons/fa';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(92,96,118,.75)',
    boxShadow: 'none',
  },
  paper: {
    width: '500px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: '0',
    padding: theme.spacing(2, 4, 3),
  },
}));

const UnconnectedClientLinkModal = (props) => {
  const classes = useStyles();
  const [copied, setCopied] = React.useState(false);

  const onCopy = () => {
    setCopied(true);
  };

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={true}
        onClose={props.handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <div className='clientmodal-container'>
            <div className='clientmodal-form'>
              <h1>Client Link</h1>
              <div className='clientmodal-button-container'>
                <CopyToClipboard
                  onCopy={onCopy}
                  text={props.link}
                >
                  <button className='clientmodal-button'>
                    {!copied ? (
                      <span style={{ fontSize: '20px' }}>
                        Copy
                      </span>
                    ) : (
                      <FaCheckCircle
                        style={{ fontSize: '20px' }}
                      />
                    )}
                  </button>
                </CopyToClipboard>
                <input
                  className='clientmodal-input'
                  type='text'
                  value={props.link}
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    project: state.project,
  };
};
let ClientLinkModal = connect(mapStateToProps)(
  UnconnectedClientLinkModal
);

export default ClientLinkModal;
