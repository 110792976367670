import React from 'react';
import Button from '../../../reusbleComponents/Button';
import validateCategory from '../config';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const UnconnectedCategorySelectButton = (props) => {
  const [categoryCount, setCategoryCount] = React.useState(0);
  //TODO: REDO mapping for catergory with string.includes
  React.useEffect(() => {
    const getCategoryCount = () => {
      let selectedUnit = '';
      let count = 0;
      if (
        props.moodboardData &&
        props.unit &&
        props.moodboardData[props.unit.unit.fields['Item Name']]
      ) {
        selectedUnit = props.unit.unit.fields['Item Name'];
        Object.keys(props.moodboardData[selectedUnit]).map((room, index) =>
          props.moodboardData[selectedUnit][room].moodboard.map((item) => {
            if (
              item.fulhausCategory === props.label ||
              (item.mappedFulhausCategory === props.label &&
                props.roomType === room)
            ) {
              count += 1;
            }
            return count;
          })
        );
        return count;
      }
    };
    setCategoryCount(getCategoryCount());
  }, [props.moodboardData, props.unit]);

  const itemQtyStyle = {
    width: '25px',
    height: '25px',
    padding: '0',
    paddingTop: '5px',
    margin: '0',
    textAlign: 'center',
    color: props.active ? '#748AA1' : 'white',
    borderRadius: '50%',
    background: props.active
      ? 'white'
      : parseInt(categoryCount) > parseInt(props.itemQty)
      ? '#fa5039'
      : '#748AA1',
    lineHeight: '15px',
    fontSize: props.active ? '12px' : '10px',
  };

  const matchRoomName = (evt) => {
    let roomName = validateCategory(evt.target.name);
    props.onClick(roomName);
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        background: props.active
          ? parseInt(categoryCount) > parseInt(props.itemQty)
            ? '#fa5039'
            : '#F5A623'
          : 'rgba(116, 138, 161, 0.5)',
        padding: '5px',
        margin: '2px 2px 2px -5px',
        borderRadius: '20px',
      }}
    >
      <Button
        name={props.label}
        label={props.label}
        enabled={true}
        onClick={(evt) => matchRoomName(evt)}
        background={'transparent'}
        fontSize={props.active ? '10px' : '10px'}
        color={props.active ? 'white' : 'white'}
        textAlign='left'
        margin='0'
        padding=' 0 5px'
        width='100%'
      />

      <p style={itemQtyStyle}>
        {categoryCount}/{props.itemQty}
      </p>
    </div>
  );
};
let mapStateToProps = (state) => {
  return {
    unit: state.unit,
    moodboardData: state.moodboardData,
  };
};
let CategorySelectButton = connect(mapStateToProps)(
  UnconnectedCategorySelectButton
);

export default withRouter(CategorySelectButton);
