import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { BlockLoading } from 'react-loadingg';
import { FETCH_URL } from '../constants';
import Footer from './Footer';
import './css/footer.css';
import './css/landingPage.css';
import RevisionModal from './modals/RevisionModal';

const UnconnectedLandingPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [revisionData, setRevisionData] = useState(null);

  useEffect(() => {
    const onInitialLoad = async (projectTitle) => {
      if (projectTitle) {
        let response = await fetch(
          `${FETCH_URL}/quotes/project/${projectTitle}`,
          {
            method: 'GET',
            credentials: 'include',
            // headers: {
            //   "Content-Type": "application/json",
            //   Accept: "application/json",
            // },
          }
        );

        let responseBody = await response.text();
        let payload = JSON.parse(responseBody);

        if (payload.success && payload.data) {
          let projectData = await loadQuoteRevision(
            payload.quoteRevisions[payload.quoteRevisions.length - 1]
          );
          props.dispatch({
            type: 'set-Project',
            value: projectData,
          });

          //set exchange rate on project load
          let exchangeRate = await getCurrencyRate(projectData.projectCurrency);
          props.dispatch({
            type: 'set-exchangeRate',
            value: exchangeRate,
          });

          let moodboardData = await loadMoodboard(projectData.quoteID.quoteID);
          setRevisionData(moodboardData);

          if (!moodboardData) {
            props.dispatch({
              type: 'initialize-moodboardData',
              value: {},
              date: Date.now(),
              revisionNum: 1,
            });

            props.history.push('/');
          }

          if (moodboardData && !props.revision) {
            props.dispatch({
              type: 'initialize-moodboardData',
              value: moodboardData[moodboardData.length - 1].data,
              date: Date.now(),
              revisionNum: moodboardData.length,
            });

            props.history.push('/');
          }

          if (moodboardData && props.revision) {
            setShowModal(true);
            props.dispatch({
              type: 'set-revision',
              value: false,
            });
          }
        } else {
          openURL('https://apps.fulhaus.com');
        }
      } else {
        openURL('https://apps.fulhaus.com');
      }
    };

    const getCurrencyRate = async (cur) => {
      return new Promise(async (resolve, reject) => {
        let response = await fetch(`${FETCH_URL}/exchangeRate/${'USD'}`, {
          method: 'GET',
          credentials: 'include',
          // headers: {
          //   "Content-Type": "application/json",
          //   Accept: "application/json",
          // },
        });

        let responseBody = await response.text();
        let payload = JSON.parse(responseBody);

        if (payload.success) {
          resolve(payload.data.conversion_rates[cur] || null);
        }
      });
    };

    const checkUser = async () => {
      let response = await fetch(`${FETCH_URL}/users/status`, {
        method: 'GET',
        credentials: 'include',
        // headers: {
        //   "Content-Type": "application/json",
        //   Accept: "application/json",
        // },
        // credentials: 'same-origin',
      });
      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);

      props.dispatch({
        type: 'set-user',
        value: payload.data,
      });
    };

    const pathArray = props.location.pathname.split('/');
    const projectTitle = pathArray[pathArray.length - 1];
    onInitialLoad(projectTitle);
    checkUser();
  }, []);

  const openURL = (url) => {
    const _window = window.location.replace(url);
    if (_window != null) {
      _window.focus();
    }
  };

  const revisionSelect = (moodboardData, index) => {
    props.dispatch({
      type: 'initialize-moodboardData',
      value: moodboardData.data,
      date: moodboardData.date,
      revisionNum: index + 1,
    });
    props.history.push('/');
  };

  const loadQuoteRevision = (quoteRevision) => {
    return new Promise(async (resolve, reject) => {
      let response = await fetch(
        `${FETCH_URL}/quotes/ID/${quoteRevision.quoteID.quoteID}`,
        {
          method: 'GET',
          credentials: 'include',
          // headers: {
          //   "Content-Type": "application/json",
          //   Accept: "application/json",
          // },
        }
      );

      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);

      if (payload.success) {
        resolve(payload.quote);
      } else {
        resolve();
      }
    });
  };

  const loadMoodboard = (quoteID) => {
    let moodboardData = null;
    return new Promise(async (resolve, reject) => {
      let response = await fetch(`${FETCH_URL}/moodboard/${quoteID}`, {
        method: 'GET',
        credentials: 'include',
        // headers: {
        //   "Content-Type": "application/json",
        //   Accept: "application/json",
        // },
      });
      let responseBody = await response.text();
      let payload2 = JSON.parse(responseBody);

      if (payload2.success) {
        moodboardData = payload2.moodboard.moodboardData;
      }
      resolve(moodboardData);
    });
  };

  const handleModalClose = () => {
    setShowModal(false);
    props.history.push('/');
  };

  return (
    <>
      <div className='landingPage-container'>
        <BlockLoading color='#FF4B30' />
      </div>
      {showModal && (
        <>
          <RevisionModal
            revisionData={revisionData}
            revisionSelect={(value, index) => {
              revisionSelect(value, index);
            }}
            handleModalClose={handleModalClose}
          />
        </>
      )}
      <Footer />
    </>
  );
};
let mapStateToProps = (state) => {
  return {
    revision: state.revision,
  };
};
let LandingPage = connect(mapStateToProps)(UnconnectedLandingPage);
export default LandingPage;
