import React from "react";
import ModalImage from "react-modal-image";
import "../../css/styleCard.css";
// props.styleData["Named Moodboard"][0].url;

export default function StyleCard(props) {
  return (
    <div className="style-card">
      <div>
        <p>{props.styleData.Name}</p>

        <ModalImage
          className="style-card-imgModal"
          small={props.styleData["Named Moodboard"][0].url}
          large={props.styleData["Named Moodboard"][0].url}
          alt={props.styleData.Name}
        />
      </div>
    </div>
  );
}
