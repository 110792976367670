import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './css/footer.css';

const UnconnectedFooter = (props) => {
  const openURL = () => {
    let url = `https://quotebuilder.fulhaus.com/start/${props.project.project.title}`;
    const _window = window.location.replace(url);
    if (_window != null) {
      _window.focus();
    }
  };

  return (
    <div className='footer'>
      <p>© Fulhaus Moodboard 2020, All Rights Reserved</p>
      <button className='footer-button' onClick={openURL}>
        Quote Builder
      </button>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    project: state.project,
  };
};
let Footer = connect(mapStateToProps)(UnconnectedFooter);

export default withRouter(Footer);
