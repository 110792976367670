import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MdMoreVert } from 'react-icons/md';

const ITEM_HEIGHT = 48;
const CanvasMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className='canvas-topBar-button-left-container'>
      <button
        className='canvas-topBar-button'
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleMenuClick}
      >
        <MdMoreVert style={{ padding: '0' }} />
      </button>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
            marginTop: '35px',
            marginLeft: '25px',
          },
        }}
      >
        <MenuItem onClick={props.handleColorPicker}>Background Color</MenuItem>
        <MenuItem onClick={props.handleAddText}>Add Text</MenuItem>
        <MenuItem onClick={() => props.handleRemoveRoom(props.canvasId)}>
          Delete Canvas
        </MenuItem>
        <MenuItem onClick={() => props.clearCanvas(props.roomType)}>
          Clear Canvas
        </MenuItem>
      </Menu>
    </div>
  );
};
export default CanvasMenu;
