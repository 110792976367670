import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '../../reusbleComponents/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(92,96,118,.75)',
    boxShadow: 'none',
  },
  paper: {
    width: '500px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: '0',
    padding: theme.spacing(2, 4, 3),
  },
}));

const UnconnectedOverwriteModal = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={true}
        onClose={props.handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <div className='switchmodal-container'>
            <div className='landingPage-form'>
              <h1>Override previous data?</h1>
              <div className='landingPage-button-container'>
                <Button
                  background='#748AA1'
                  borderRadius='100px'
                  width='50%'
                  enabled={true}
                  onClick={props.overwriteAirtable}
                  color='white'
                  label='Yes'
                />
                <Button
                  background='#FF4B30'
                  borderRadius='100px'
                  width='50%'
                  enabled={true}
                  onClick={props.handleModalClose}
                  color='white'
                  label='Cancel'
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    project: state.project,
  };
};
let OverwriteModal = connect(mapStateToProps)(UnconnectedOverwriteModal);

export default OverwriteModal;
