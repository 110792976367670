import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useDrop } from 'react-dnd';
import { maxBy } from 'lodash';
import produce from 'immer';
import { SketchPicker } from 'react-color';
import Resizer from '../ImageTools/Resizer';
import { MIN_DIMENSION, TOP_BOUND } from '../../../constants';
import ImageOnCanvas from '../ImageTools/ImageOnCanvas';
import Text from './Text';
import '../../css/Canvas.css';
import DropDownRooms from '../inputComponents/DropDownRooms';
import CanvasMenu from '../inputComponents/CanvasMenu';
import ProductDetailModal from '../../modals/ProductDetailModal';
import AirtableProductModal from '../../modals/AirtableProductModal';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const usePrevious = (value) => {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const Canvas = (props) => {
  const [state, setState] = useState({
    list: props.list,
    background: '#fff',
    showColorPicker: false,
    roomType: Object.keys(props.list)[0],
  });
  const [open, setOpen] = React.useState(false);
  const [showDetail, setShowDetail] = useState({});
  const [historySelect, setHistorySelect] = useState('');
  const [showAirtableDetail, setShowAirtableDetail] = useState({});
  const preList = usePrevious(props.list);
  const [{ isOver }, drop] = useDrop({
    accept: 'product-card',
    drop: (item, monitor) => {
      return props.addSourcedItem(item.product, state.roomType);
    },
    collect: (monitor) => {
      return {
        isOver: !!monitor.isOver(),
      };
    },
  });

  useEffect(() => {
    if (preList !== props.list) {
      setState((state) =>
        produce(state, (st) => {
          st.list = props.list;
        })
      );

      if (!props.list[state.roomType]) {
        setState((state) =>
          produce(state, (st) => {
            st.roomType = Object.keys(props.list)[0];
          })
        );
      }
    }
  }, [props.list]);

  const handleStart = (item) => {
    if (!item || item.isResizing) {
      return false;
    }
    const targetList = state.list[state.roomType].moodboard;
    const itemMaxZ = maxBy(
      targetList,
      (e) => {
        return e.moodboardMeta.position.zIndex;
      },
      'zIndex'
    );
    if (!itemMaxZ) {
      return;
    }
    if (
      itemMaxZ &&
      itemMaxZ.moodboardMeta.position.zIndex !==
        item.moodboardMeta.position.zIndex
    ) {
      const index = targetList.findIndex(
        (i) => i.moodboardId === item.moodboardId
      );
      const newList = [...targetList];
      newList[index] = {
        ...item,
        moodboardMeta: {
          position: {
            top: item.moodboardMeta.position.top,
            left: item.moodboardMeta.position.left,
            zIndex: item.moodboardMeta.position.zIndex + 1,
          },
          dimensions: {
            width: item.moodboardMeta.dimensions.width,
            height: item.moodboardMeta.dimensions.height,
          },
          rotation: item.moodboardMeta.rotation,
          flipImg: item.moodboardMeta.flipImg,
          aspectRatio: item.moodboardMeta.aspectRatio,
        },
      };
      props.onChange(newList, state.roomType);
    }
  };

  const handleStop = (item, node) => {
    const { x, y } = node;
    let newList = state.list[state.roomType].moodboard;
    let modifiedItem = {
      ...item,
      moodboardMeta: {
        position: {
          top: y,
          left: x,
          zIndex: item.moodboardMeta.zIndex,
        },
        dimensions: {
          width: item.moodboardMeta.dimensions.width,
          height: item.moodboardMeta.dimensions.height,
        },
        rotation: item.moodboardMeta.rotation,
        flipImg: item.moodboardMeta.flipImg,
        aspectRatio: item.moodboardMeta.aspectRatio,
      },
    };

    let filterList = newList.filter((i) => i.moodboardId !== item.moodboardId);

    filterList.push(modifiedItem);
    let room = state.roomType;

    if (item && modifiedItem) {
      props.onChange(filterList, room);
    }
  };

  const handleResizeStop = (id) => {
    const item = state.list[state.roomType].moodboard.find(
      (i) => i.moodboardId === id
    );

    if (item) {
      props.onChange(state.list[state.roomType].moodboard, state.roomType);
    }
  };

  const handleResizing = (id, deltaX, deltaY, dims, product) => {
    const targetList = state.list[state.roomType].moodboard;
    const index = targetList.findIndex((item) => item.moodboardId === id);

    let newWidth =
      targetList[index].moodboardMeta.dimensions.width +
      (deltaX / targetList[index].moodboardMeta.dimensions.width) * 200;

    let newHeight =
      targetList[index].moodboardMeta.dimensions.height +
      (deltaX / targetList[index].moodboardMeta.dimensions.height) * 200;

    let newBkgHeight =
      targetList[index].moodboardMeta.dimensions.height + deltaY;
    let newBkgwidth = targetList[index].moodboardMeta.dimensions.width + deltaX;

    if (product.bkgImageURL || !product.moodboardMeta.aspectRatio) {
      targetList[index].moodboardMeta.dimensions.width =
        newBkgwidth > MIN_DIMENSION ? newBkgwidth : MIN_DIMENSION;
      targetList[index].moodboardMeta.dimensions.height =
        newBkgHeight > MIN_DIMENSION ? newBkgHeight : MIN_DIMENSION;
    } else {
      targetList[index].moodboardMeta.dimensions.width =
        newWidth > MIN_DIMENSION ? newWidth : MIN_DIMENSION;
      targetList[index].moodboardMeta.dimensions.height =
        newHeight > MIN_DIMENSION ? newHeight : MIN_DIMENSION;
    }

    let tmp = Object.assign({}, props.list);
    tmp[state.roomType].moodboard = targetList;
    setState((state) =>
      produce(state, (st) => {
        st.list = tmp;
      })
    );
  };

  const setResizeMode = (id, isResizing) => {
    const targetList = state.list[state.roomType].moodboard;
    const index = targetList.findIndex((item) => item.moodboardId === id);
    targetList[index].isResizing = isResizing;
    let tmp = Object.assign({}, props.list);
    tmp[state.roomType].moodboard = targetList;
    setState((state) =>
      produce(state, (st) => {
        st.list = tmp;
      })
    );
  };

  const handleColorPicker = (event) => {
    if (event === 'off') {
      setState((state) =>
        produce(state, (st) => {
          st.showColorPicker = false;
        })
      );
      return;
    }
    setState((state) =>
      produce(state, (st) => {
        st.showColorPicker = !state.showColorPicker;
      })
    );
  };

  const handleBKChangeComplete = (color) => {
    props.setBackground(color.hex, state.roomType);
  };

  const dropDownChange = (evt) => {
    let roomType = evt.target.value;
    if (evt.target) {
      setState((state) =>
        produce(state, (st) => {
          st.roomType = roomType;
        })
      );
    }
  };

  const handleAddText = () => {
    const textId = Date.now();
    let tmp = Object.assign({}, state.list);
    tmp[state.roomType].text[textId] = {
      text: 'Enter Text',
      textSize: '18',
      hex: '#00000',
      activeFontFamily: 'Open Sans',
      position: { x: 0, y: 0 },
    };
    setState((state) =>
      produce(state, (st) => {
        st.list = tmp;
      })
    );
  };

  const textPositionChange = (textID, position) => {
    let tmp = Object.assign({}, state.list);
    tmp[state.roomType].text[textID] = {
      ...tmp[state.roomType].text[textID],
      position: position,
    };
    setState((state) =>
      produce(state, (st) => {
        st.list = tmp;
      })
    );
  };

  const removeText = (textID) => {
    let tmp = Object.assign({}, state.list);
    delete tmp[state.roomType].text[textID];
    setState((state) =>
      produce(state, (st) => {
        st.list = tmp;
      })
    );
  };

  const onChangeText = (textID, textData) => {
    let tmp = Object.assign({}, state.list);
    tmp[state.roomType].text[textID] = {
      text: textData.text,
      textSize: textData.textSize,
      hex: textData.hex,
      activeFontFamily: textData.activeFontFamily,
      position: textData.position,
    };
    setState((state) =>
      produce(state, (st) => {
        st.list = tmp;
      })
    );
  };
  const handleClose = () => {
    setShowDetail(!showDetail);
    setShowAirtableDetail(!showAirtableDetail);
  };

  const handleShowDetails = (item) => {
    let tmp = Object.assign({}, showDetail);
    if (showDetail[item._id]) {
      tmp[item._id] = false;
      setShowDetail(tmp);
      return;
    }
    tmp[item._id] = true;
    setShowDetail(tmp);
  };

  const handleShowAirtableDetails = (item) => {
    let tmp = Object.assign({}, showAirtableDetail);
    if (showAirtableDetail[item._id]) {
      tmp[item._id] = false;
      setShowAirtableDetail(tmp);
      return;
    }
    tmp[item._id] = true;
    setShowAirtableDetail(tmp);
  };

  const setImg = (index, itemId) => {
    const targetList = state.list[state.roomType].moodboard;
    const _index = targetList.findIndex((item) => item.moodboardId === itemId);
    targetList[_index].displayedImage = targetList[_index].imageURLs[index];
    props.onChange(targetList, state.roomType);
  };

  const setCropImg = async (imgSrc, itemId) => {
    let dims = await getMeta(imgSrc);
    const targetList = state.list[state.roomType].moodboard;
    const _index = targetList.findIndex((item) => item.moodboardId === itemId);
    targetList[_index].displayedImage = imgSrc;
    targetList[_index].moodboardMeta.dimensions = dims;
    props.onChange(targetList, state.roomType);
  };

  const getMeta = (url) => {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.addEventListener('load', function () {
        resolve({
          width: this.naturalWidth,
          height: this.naturalHeight,
        });
      });
      img.src = url;
    });
  };

  const setCroppingMode = (id, isCropping) => {
    const targetList = state.list[state.roomType].moodboard;
    const index = targetList.findIndex((item) => item.moodboardId === id);
    targetList[index].isCropping = isCropping;
    let tmp = Object.assign({}, props.list);
    tmp[state.roomType].moodboard = targetList;
    setState((state) =>
      produce(state, (st) => {
        st.list = tmp;
      })
    );
  };

  const handleHistroyClose = () => {
    setOpen(false);
  };

  const handleHistoryOpen = () => {
    setOpen(true);
  };

  const handleHistoryChange = (event) => {
    let _id = null;
    let historyData = Object.keys(props.historyList[state.roomType]).map(
      (id, index) => {
        if (id === event.target.value) {
          console.log('id', id, props.historyList[state.roomType][id].data);
          _id = id;
          return props.historyList[state.roomType][id].data;
        }
      }
    );

    console.log({ historyData });
    console.log(historyData[historyData.length - 1]);
    props.handleStateHistory(
      state.roomType,
      _id,
      historyData[historyData.length - 1]
    );
  };

  return (
    <>
      {state.list[state.roomType] ? (
        <div
          key={props.key}
          ref={drop}
          style={{
            backgroundColor:
              state.list[state.roomType].meta.background || '#fff',
            boxShadow: 'none',
            outline: 0,
          }}
          style={{
            backgroundColor: isOver
              ? '#D6FAC4'
              : state.list[state.roomType].meta.background || 'white',
          }}
          className='canvas'
        >
          <div className='canvas-topBar'>
            <div
              style={{
                display: 'flex',
                margin: '0',
                padding: '0',
              }}
            >
              <CanvasMenu
                handleColorPicker={handleColorPicker}
                handleAddText={handleAddText}
                canvasId={props.canvasId}
                handleRemoveRoom={props.handleRemoveRoom}
                clearCanvas={props.clearCanvas}
                roomType={state.roomType}
              />

              {/**<div>
                <FormControl>
                  <Select
                    labelId='navDropDown-label'
                    id='navDropDown-select'
                    open={open}
                    onClose={handleHistroyClose}
                    onOpen={handleHistoryOpen}
                    onChange={handleHistoryChange}
                    value={''}
                    disableUnderline={true}
                  >
                    {props.historyList &&
                      props.historyList[state.roomType] &&
                      Object.keys(props.historyList[state.roomType]).map(
                        (id, index) => {
                          return (
                            <MenuItem key={index} value={id}>
                              {props.historyList[state.roomType][id].action}
                            </MenuItem>
                          );
                        }
                      )}
                  </Select>
                </FormControl>
                      </div>**/}
            </div>

            <>
              {state.showColorPicker && (
                <>
                  {/* <Draggable
                    defaultPosition={{
                      x: 50,
                      y: 50,
                    }}
                    bounds='body'
                  > */}
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '1000',
                      marginTop: '50px',
                      marginLeft: '50px',
                    }}
                  >
                    <button
                      style={{
                        alignItems: 'right',
                      }}
                      onClick={handleColorPicker}
                    >
                      x
                    </button>
                    <SketchPicker
                      color={
                        state.list[state.roomType].meta.background ||
                        state.background
                      }
                      onChangeComplete={handleBKChangeComplete}
                    />
                  </div>
                  {/* </Draggable> */}
                </>
              )}
            </>

            <div className='canvas-topBar-button-right'>
              <DropDownRooms
                list={state.list}
                selected={state.roomType}
                dropDownChange={dropDownChange}
              />
            </div>
          </div>
          {state.list[state.roomType].moodboard &&
            state.list[state.roomType].moodboard.map((item, index) => {
              const dims = {
                width: item.moodboardMeta.dimensions.width,
                height: item.moodboardMeta.dimensions.height,
              };
              return (
                <>
                  <Draggable
                    key={index + item.moodboardId}
                    defaultPosition={{
                      x: item.moodboardMeta.position.left,
                      y: item.moodboardMeta.position.top + TOP_BOUND,
                    }}
                    disabled={item.isResizing || item.isCropping}
                    onStart={() => handleStart(item)}
                    onStop={(e, node) => {
                      handleStop(item, node);
                    }}
                    // bounds='parent'
                  >
                    <div
                      style={{
                        ...dims,
                        zIndex: item.moodboardMeta.position.zIndex,
                      }}
                      className='item'
                    >
                      <div className='draggable-content'>
                        <ImageOnCanvas
                          style={dims}
                          url={item.bkgImageURL || item.displayedImage}
                          alt=''
                          imgArray={item.imageURLs ? item.imageURLs : null}
                          setImg={(index, id) => setImg(index, id)}
                          item={item}
                          roomType={state.roomType}
                          removeBG={(item, roomType) =>
                            props.removeBG(item, roomType)
                          }
                          delete={(item, roomType) =>
                            props.onRemove(item, roomType)
                          }
                          detailsModal={(item) => handleShowDetails(item)}
                          airtableDetailsModal={(item) =>
                            handleShowAirtableDetails(item)
                          }
                          setCropImg={(imgSrc, itemId) =>
                            setCropImg(imgSrc, itemId)
                          }
                          setCroppingMode={(id, isResizing) =>
                            setCroppingMode(id, isResizing)
                          }
                          list={state.list}
                          onListChange={(newList, roomType) => {
                            props.onChange(newList, roomType);
                          }}
                        />
                        <div
                          className={
                            item.isResizing
                              ? 'fh-moodboard--canvas--item-options--hidden'
                              : 'fh-moodboard--canvas--item-options'
                          }
                        >
                          <Resizer
                            id={item.moodboardId}
                            item={item}
                            position={dims}
                            isResizing={item.isResizing}
                            setResizeMode={setResizeMode}
                            onResize={handleResizing}
                            onResizeStop={handleResizeStop}
                          />
                        </div>
                      </div>
                    </div>
                  </Draggable>
                  {showDetail[item._id] && (
                    <ProductDetailModal
                      canvasTrigger={true}
                      handleClose={handleClose}
                      item={item}
                    />
                  )}
                  {showAirtableDetail[item._id] && (
                    <AirtableProductModal
                      handleClose={handleClose}
                      item={item}
                      canvas={true}
                    />
                  )}
                </>
              );
            })}
          {state.list[state.roomType].text &&
            Object.keys(state.list[state.roomType].text).map(
              (textID, index) => (
                <>
                  <Text
                    textID={textID}
                    textData={state.list[state.roomType].text[textID]}
                    onTextChange={onChangeText}
                    removeText={removeText}
                    disabled={false}
                    textPositionChange={textPositionChange}
                  />
                </>
              )
            )}
        </div>
      ) : (
        console.table('state.list', state.list, props.list, state.roomType)
      )}
    </>
  );
};

export default Canvas;
