import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '../../reusbleComponents/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(92,96,118,.75)',
    boxShadow: 'none',
  },
  paper: {
    width: '500px',
    height: '500px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: '0',
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 400,
    minHeight: 500,
    border: 'none',
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const UnconnectedAirtableProductModal = (props) => {
  const [imageURLArr, setImageURLArr] = React.useState(null);

  React.useEffect(() => {
    const getImgArray = async () => {
      await imgArray(props.item.imageURLs);
    };
    if (!props.canvas) {
      getImgArray();
    }
    if (props.canvas) {
      setImageURLArr(props.item.imageURLs);
    }
  }, []);

  const createData = (name, data) => {
    if (data === 'undefined' || data === null) {
      return { name, data: 'ERROR!' };
    }
    if (name === 'Image URL' && data !== null) {
      let link = data;
      return { name, link };
    }
    if (name === 'Description' && data !== null) {
      return { name, data, type: 'description' };
    }
    return { name, data };
  };

  const imgArray = (imgArr) => {
    let tmpArray = [];
    return new Promise((resolve, reject) => {
      for (let index = 0; index < imgArr.length; index++) {
        tmpArray.push(imgArr[index].url);
      }
      setImageURLArr(tmpArray);
      resolve();
    });
  };

  const rows = [
    createData('Name', `${props.item.itemName}`),
    createData('SKU', props.item.SKU),
    createData('Fulhaus Category', props.item.fulhausCategory),
    createData('Vendor Name', props.item.vendorName),
    createData('Image URL', props.item.imageURLs[0]),
    createData('Weight', `${props.item.weight}${props.item.weightUnit}`),
    createData(
      'Dimension',
      `${props.item.length}L ${props.item.width}W ${props.item.height}H ${props.item.dimensionUnit}`
    ),
    createData(
      'Trade Price',
      props.project.projectCurrency === 'USD'
        ? `${props.item.priceUSA} USD`
        : `${props.item.priceUSA} USD --> ${props.item.priceCAD} CAD`
    ),
  ];

  const classes = useStyles();

  return (
    <>
      {imageURLArr && (
        <div>
          <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            className={classes.modal}
            open={true}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={true}>
              <div className='airtable-productDetail-container'>
                <div className='airtable-productDetail-top'>
                  <div className='airtable-productDetail-left'>
                    <Carousel
                      width={'450px'}
                      showArrows={true}
                      showThumbs={false}
                    >
                      {imageURLArr &&
                        imageURLArr.map((url) => {
                          return (
                            <img
                              alt='airtable-product-img'
                              style={{ width: '450px' }}
                              src={url}
                            />
                          );
                        })}
                    </Carousel>
                  </div>
                  <div className='airtable-productDetail-right'>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label='customized table'
                      >
                        <TableBody>
                          {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                              <StyledTableCell component='th' scope='row'>
                                {row.name}
                              </StyledTableCell>
                              {row.data ? (
                                <StyledTableCell
                                  align={
                                    row.type === 'description' ? 'left' : 'left'
                                  }
                                >
                                  {row.data}
                                </StyledTableCell>
                              ) : (
                                <StyledTableCell align='left'>
                                  <a
                                    target='_blank'
                                    href={row.link}
                                    rel='noopener noreferrer'
                                  >
                                    LINK
                                  </a>
                                </StyledTableCell>
                              )}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
                <div className='airtable-productDetail-button-container'>
                  <Button
                    background='white'
                    borderRadius='100px'
                    width='10%'
                    enabled={true}
                    onClick={props.handleClose}
                    color='grey'
                    label='X'
                  />
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      )}
    </>
  );
};

let mapStateToProps = (state) => {
  return {
    project: state.project,
  };
};
let AirtableProductModal = connect(mapStateToProps)(
  UnconnectedAirtableProductModal
);

export default AirtableProductModal;
