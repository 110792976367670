import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { TOP_BOUND } from '../../constants';
import '../css/gridView.css';
import Text from '../moodboard/canvas/Text';
import ImageToBase64 from './ImageToBase64';

const GridCanvas = (props) => {
  const [list, setList] = useState(null);
  const [roomType, setRoomType] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [textList, setTextList] = useState(null);

  useEffect(() => {
    setList(props.list.moodboard);
    setRoomType(props.roomType);
    setBackgroundColor(props.list.meta.background);
    setTextList(props.list.text);
  }, []);

  return (
    <>
      {list && roomType && (
        <div className='gridview-canvas-container'>
          <h3
            style={{
              borderBottom: '0.5px solid #B8C5D3',
              padding: '10px ',
              marginRight: '10px',
              display: 'flex',
              justifyContent: 'center',
              color: '#878b98',
            }}
          >
            {roomType}
          </h3>
          <div
            style={{
              backgroundColor: backgroundColor || '#fff',
              boxShadow: 'none',
              outline: 0,
            }}
            className='gridview-canvas'
          >
            {list.map((item, index) => {
              const dims = {
                width: item.moodboardMeta.dimensions.width,
                height: item.moodboardMeta.dimensions.height,
              };
              return (
                <>
                  <Draggable
                    key={index + item._id}
                    defaultPosition={{
                      x: item.moodboardMeta.position.left,
                      y: item.moodboardMeta.position.top + TOP_BOUND,
                    }}
                    disabled={true}
                  >
                    <div
                      style={{
                        ...dims,
                        zIndex: item.moodboardMeta.position.zIndex,
                      }}
                      className='item'
                    >
                      <div>
                        {/* <img
                          style={dims}
                          src={
                            item.bkgImageURL ||
                            item.imageURL
                          }
                          alt=''
                        /> */}
                        {/* <ImageOnCanvas
                          style={dims}
                          url={
                            item.bkgImageURL ||
                            item.imageURL
                          }
                          alt=''
                        /> */}

                        <ImageToBase64
                          gridView={true}
                          style={dims}
                          url={item.bkgImageURL || item.displayedImage}
                          alt=''
                          imgArray={item.imageURLs ? item.imageURLs : null}
                          item={item}
                        />
                      </div>
                    </div>
                  </Draggable>
                </>
              );
            })}
            {textList &&
              Object.keys(textList).map((textID, index) => (
                <>
                  <Text
                    textID={textID}
                    textData={textList[textID]}
                    disabled={true}
                  />
                </>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default GridCanvas;
