const validateCategory = (keyWord) => {
  switch (keyWord) {
    // case 'Accessories':
    //   return 'Accessory';
    // case 'Dining Chairs':
    //   return 'Dining Chair';
    // case 'Bed - Queen':
    //   return 'Queen Bed';
    // case 'Matress - Queen':
    //   return 'Matress - Queen';
    default:
      return keyWord;
  }
};

export default validateCategory;
