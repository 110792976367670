import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '../../reusbleComponents/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { FaFileDownload } from 'react-icons/fa';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(92,96,118,.75)',
    boxShadow: 'none',
  },
  paper: {
    width: '500px',
    backgroundColor: 'white',
    boxShadow: 'none',
    border: '0',
    padding: theme.spacing(2, 4, 3),
  },
}));

const UnconnectedRevisionModal = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);

  const handleListItemClick = (value, index) => {
    setValue(value);
    props.revisionSelect(value, index);
  };

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={true}
        onClose={value && props.handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <div className='rivision-modal'>
            <div className='rivision-landingPage-form'>
              <h1>Moodboard Entries</h1>
              <List>
                {props.revisionData.map((savedMoodboard, index) => (
                  <ListItem
                    button
                    onClick={() => handleListItemClick(savedMoodboard, index)}
                    key={savedMoodboard.date}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <FaFileDownload />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${index + 1}. ${moment(
                        savedMoodboard.date
                      ).format('lll')} ${
                        savedMoodboard.user
                          ? `(${savedMoodboard.user.fullName.split(' ')[0]})`
                          : ''
                      }`}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
            <div className='landingPage-button-container'>
              <Button
                background='#FF4B30'
                borderRadius='100px'
                width='100%'
                enabled={true}
                onClick={props.handleModalClose}
                color='white'
                label='Cancel'
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    project: state.project,
  };
};
let RevisionModal = connect(mapStateToProps)(UnconnectedRevisionModal);

export default RevisionModal;
