import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './css/navbar.css';
import Button from '../reusbleComponents/Button';
import SwitchProjectModal from './modals/SwitchProjectModal';
import { withRouter } from 'react-router-dom';
import NavDropDown from './NavDropDown';
import { FETCH_URL } from '../constants';

const UnconnectedNavBar = ({
  dispatch,
  history,
  project,
  projectTitle,
  revisionNum,
  unit,
  moodboardData,
}) => {
  const [isSwitchModal, setSwitchModal] = useState(false);
  const [projectTotal, setProjectTotal] = useState(0);
  const [unitTotal, setUnitTotal] = useState(0);

  const handleClose = () => {
    setSwitchModal(!isSwitchModal);
  };

  useEffect(() => {
    const getUnitTotal = () => {
      let count = 0;
      let selectedUnit = unit.unit.fields['Item Name'];
      Object.keys(moodboardData[selectedUnit]).map((room, index) =>
        moodboardData[selectedUnit][room].moodboard.map((item) => {
          if (item.MAP || item.airtableProduct) {
            item.airtableProduct
              ? (count += item.tradePrice)
              : (count += item.MAP);
          }

          return count;
        })
      );
      return count;
    };

    if (moodboardData && unit && moodboardData[unit.unit.fields['Item Name']]) {
      setUnitTotal(getUnitTotal());
    }
  }, [moodboardData, unit]);

  useEffect(() => {
    const getProjectTotal = () => {
      let count = 0;
      Object.keys(moodboardData).map((unit, index) =>
        Object.keys(moodboardData[unit]).map((room, index) =>
          moodboardData[unit][room].moodboard.map((item) => {
            if (item.MAP || item.airtableProduct) {
              item.airtableProduct
                ? (count += item.tradePrice)
                : (count += item.MAP);
            }
            return count;
          })
        )
      );
      return count;
    };

    if (unit) {
      setProjectTotal(getProjectTotal());
    }
  }, [moodboardData, unit]);

  // useEffect(async () => {
  //   if (project) {
  //     getCurrencyRate();
  //   }
  // }, []);

  // const getCurrencyRate = async () => {
  //   let response = await fetch(`${FETCH_URL}/exchangeRate/${'USD'}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //   });

  //   // let response = await fetch(
  //   //   `https://v6.exchangerate-api.com/v6/129db7eac28224585fae1c52/latest/`,
  //   //   {
  //   //     method: 'GET',
  //   //   }
  //   // );
  //   let responseBody = await response.text();
  //   let payload = JSON.parse(responseBody);
  //   console.log('TEST', payload);
  //   if (payload.success) {
  //     dispatch({
  //       type: 'set-exchangeRate',
  //       value: payload.data.conversion_rates[project.projectCurrency] || null,
  //     });
  //   }
  // };

  const revisions = () => {
    dispatch({
      type: 'set-revision',
      value: true,
    });
    history.push(`/start/${projectTitle}`);
  };

  return (
    project && (
      <>
        <div className='moodboard-navbar'>
          <div className='moodboard-navbar-left'>
            <a href='https://apps.fulhaus.com/'>
              <img
                width='60px'
                src='../assets/logo/fulhaus-logo.png'
                alt='Logo-img'
              />
            </a>
            <h3>MoodBoard</h3>
          </div>

          <div className='moodboard-navbar-right'>
            <div className='project-description'>
              <div className='project-title'>
                <div>
                  <span
                    style={{
                      color: '#060e23',
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    {revisionNum && `r-${revisionNum} `}{' '}
                  </span>
                  {projectTitle + ' '}
                  <span
                    style={{
                      color: '#060e23',
                      fontSize: '14px',
                    }}
                  >
                    (
                    {project.deliveryDate
                      ? project.deliveryDate.split('T')[0] || '-'
                      : 'no delivery date set'}
                    )
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    AlignItems: 'center',
                  }}
                >
                  <Button
                    label='Revisions'
                    background='#ff4b30'
                    color='white'
                    padding='2px 5px'
                    margin='0 0 0 5px'
                    border='none'
                    borderRadius='20px'
                    fontSize='8px'
                    enabled={true}
                    width='100%'
                    onClick={() => revisions()}
                  />
                </div>
              </div>
              <div
                style={{
                  marginRight: '25px',
                  marginTop: '10px',
                  color: '#060e23',
                  fontSize: '12px',
                }}
              >
                {project.project['Project Address'] || ''}
              </div>

              <div
                style={{
                  color: 'rgba(242, 242, 242, 0.8)',
                  padding: '10px 10px',
                }}
              >
                <NavDropDown units={project.quoteData} />
              </div>
            </div>
            <div className='project-cost'>
              <div
                style={{
                  color: 'white',
                }}
              >
                <h5>Project Budget</h5>
                <h5>${project.budget && project.budget.toFixed(2)}</h5>
              </div>
              <div>
                <h5>Items used</h5>
                <h5>${projectTotal.toFixed(2) || '0'}</h5>
              </div>
              <div
                style={{
                  borderTop: '0.5px solid #F2F2F2',
                  marginTop: '8px',
                  padding: '8px 0',
                  color: 'white',
                }}
              >
                <h5>Amount left:</h5>
                <h5>${(project.budget - projectTotal).toFixed(2)}</h5>
              </div>
            </div>
            <div className='project-cost'>
              <div
                style={{
                  color: 'white',
                }}
              >
                <h5>Unit Budget</h5>
                <h5>${unit && unit.unit.fields['Price (USD)'].toFixed(2)}</h5>
              </div>
              <div>
                <h5>Items used</h5>
                <h5>${unitTotal.toFixed(2) || '0'}</h5>
              </div>
              <div
                style={{
                  borderTop: '0.5px solid #F2F2F2',
                  marginTop: '8px',
                  padding: '8px 0',
                  color: 'white',
                }}
              >
                <h5>Amount left:</h5>
                <h5>
                  $
                  {unit &&
                    (unit.unit.fields['Price (USD)'] - unitTotal).toFixed(2)}
                </h5>
              </div>
            </div>
          </div>
        </div>
        {isSwitchModal && <SwitchProjectModal handleClose={handleClose} />}
      </>
    )
  );
};

let mapStateToProps = (state) => {
  return {
    revisionNum: state.revisionNum,
    project: state.project,
    totalPrice: state.totalPrice,
    projectTitle: state.projectTitle,
    unit: state.unit,
    moodboardData: state.moodboardData,
  };
};
let NavBar = connect(mapStateToProps)(UnconnectedNavBar);

export default withRouter(NavBar);
