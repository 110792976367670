import React, { useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';
import { BlockLoading } from 'react-loadingg';
import GridCanvas from '../GridCanvas';

import { FaFileDownload } from 'react-icons/fa';
import { PDFExport } from '@progress/kendo-react-pdf';
import { FETCH_URL } from '../../../constants';
import '../../css/gridView.css';

const ref = React.createRef();

const ClientViewContainer = (props) => {
  const [moodboardData, setMoodboardData] = useState(null);
  const [unitName, setUnitName] = useState(null);
  const [quoteID, setQuoteID] = useState(null);

  useEffect(() => {
    const getMoodboardData = async (quoteID) => {
      let response = await fetch(`${FETCH_URL}/moodboard/${quoteID}`, {
        method: 'GET',
        credentials: 'include',
        // headers: {
        //   'Content-Type': 'application/json',
        //   Accept: 'application/json',
        // },
      });
      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);

      if (payload.success) {
        setMoodboardData(
          payload.moodboard.moodboardData[
            payload.moodboard.moodboardData.length - 1
          ]
        );
      }
    };

    let _quoteID = props.match.params.quoteID;
    let _unitName = new URLSearchParams(props.location.search).get('unitName');

    if (_quoteID) {
      getMoodboardData(_quoteID);
      setQuoteID(_quoteID);
    }
    if (_unitName) {
      setUnitName(_unitName);
    }
  }, []);

  const generatePdf = () => {
    ref.current.save();
  };

  return (
    <>
      {moodboardData && unitName && quoteID && moodboardData.data[unitName] ? (
        <>
          <div className='gridView-top-banner'>
            <button
              style={{
                background: 'rgba(116, 138, 161, 0.9)',
                border: 'none',
                borderRadius: '5px',
                padding: '6px 6px',
              }}
              onClick={() => generatePdf()}
            >
              <FaFileDownload style={{ fontSize: '1.8em' }} />
            </button>
            <h3>{unitName}</h3>
          </div>

          <PDFExport
            ref={ref}
            paperSize='auto'
            fileName={`Moodboard_-_${quoteID}_-_${unitName}`}
            author='Fulhaus'
          >
            <div className='gridView-container'>
              {moodboardData.data &&
                unitName &&
                Object.keys(moodboardData.data[unitName]).map((room, index) => {
                  return (
                    moodboardData.data[unitName][room].moodboard.length > 0 && (
                      <GridCanvas
                        list={moodboardData.data[unitName][room]}
                        roomType={room}
                      />
                    )
                  );
                })}
            </div>
          </PDFExport>
        </>
      ) : (
        <div className='landingPage-container'>
          <BlockLoading color='#FF4B30' />
        </div>
      )}
    </>
  );
};

export default withRouter(ClientViewContainer);
