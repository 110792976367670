import * as React from 'react';
import { FaCheck, FaTrash, FaPaintBrush } from 'react-icons/fa';
import { GoTextSize } from 'react-icons/go';
import { ChromePicker } from 'react-color';
import FontPicker from 'font-picker-react';
import Draggable from 'react-draggable';

const styles = {
  multiInputsContainer: {
    marginTop: '5px',
    border: '1px solide white',
    borderRadius: '5px',
  },
};

const Text = (props) => {
  const [position, setPosition] = React.useState({
    x: 0,
    y: 0,
  });
  const [text, setText] = React.useState(null);
  const [showInput, setShowInput] = React.useState(false);
  const [textSize, setTextSize] = React.useState(null);
  const [colorPicker, setColorPicker] = React.useState(false);
  const [hex, setHex] = React.useState(null);
  const [activeFontFamily, setActiveFontFamily] = React.useState(
    props.textData.activeFontFamily
  );
  const [showFontPicker, setShowFontPicker] = React.useState(false);
  const [showFontSizeSlider, setShowFontSizeSlider] = React.useState(false);

  React.useEffect(() => {
    if (props.textData) {
      setText(props.textData.text);
      setTextSize(props.textData.textSize);
      setHex(props.textData.hex);
      setActiveFontFamily(props.textData.activeFontFamily);
      setPosition(props.textData.position);
    }
  }, []);

  React.useEffect(() => {
    if (props.textData) {
      setPosition(props.textData.position);
    }
  }, [props.textData]);

  const handleStop = (node) => {
    const { x, y } = node;
    props.textPositionChange(props.textID, { x: x, y: y });
  };

  const handleColorChange = (color) => {
    setHex(color.hex);
  };

  const handleTextSizeChange = (event) => {
    setTextSize(event.target.value);
  };

  const handleOnCheck = () => {
    setShowInput(!showInput);
    setColorPicker(false);
    setShowFontPicker(false);
    setShowFontSizeSlider(false);
    let textData = {
      text: text,
      textSize: textSize,
      hex: hex,
      activeFontFamily: activeFontFamily,
      position: position,
    };
    props.onTextChange(props.textID, textData);
  };

  return (
    <>
      {props.textData && (
        <Draggable
          position={{ x: position.x, y: position.y }}
          defaultPosition={{ x: position.x, y: position.y }}
          onStop={(e, node) => handleStop(node)}
          bounds='parent'
          disabled={props.disabled}
          enableUserSelectHack={false}
          cancel='canvas-textDrop-input'
        >
          <div className='canvas-textDrop-container'>
            <div
              unselectable='on'
              onselectstart='return false;'
              onmousedown='return false;'
              className='canvas-textDrop-text'
            >
              <h1
                className='apply-font'
                style={{
                  color: hex,
                  fontSize: `${textSize}px`,
                }}
                onDoubleClick={() => {
                  !props.disabled && setShowInput(!showInput);
                }}
              >
                {text}
              </h1>
            </div>
            <div className='canvas-textDrop'>
              {showInput && (
                <input
                  width='40px'
                  className='canvas-textDrop-input'
                  onChange={(evt) => {
                    setText(evt.target.value);
                  }}
                  value={text}
                />
              )}
              {showInput && (
                <>
                  <button
                    className='canvas-textDrop-button'
                    onClick={() => {
                      handleOnCheck();
                    }}
                  >
                    <FaCheck />
                  </button>
                  <button
                    className='canvas-textDrop-button'
                    onClick={() => {
                      props.removeText(props.textID);
                    }}
                  >
                    <FaTrash />
                  </button>
                  <button
                    className='canvas-textDrop-button'
                    onClick={() => {
                      setColorPicker(!colorPicker);
                      setShowFontPicker(false);
                      setShowFontSizeSlider(false);
                    }}
                  >
                    <FaPaintBrush />
                  </button>
                  <button
                    className='canvas-textDrop-button'
                    onClick={() => {
                      setShowFontPicker(!showFontPicker);
                      setColorPicker(false);
                      setShowFontSizeSlider(false);
                    }}
                  >
                    T
                  </button>
                  <button
                    className='canvas-textDrop-button'
                    onClick={() => {
                      setShowFontSizeSlider(!showFontSizeSlider);
                      setColorPicker(false);
                      setShowFontPicker(false);
                    }}
                  >
                    <GoTextSize />
                  </button>
                </>
              )}
            </div>
            <div style={styles.multiInputsContainer}>
              {showFontPicker && (
                <FontPicker
                  apiKey='AIzaSyBW2QWhTw5VGNnQ4cZbyrux5FW4fye6MBk'
                  activeFontFamily={activeFontFamily}
                  onChange={(nextFont) => setActiveFontFamily(nextFont.family)}
                />
              )}
            </div>
            <div style={styles.multiInputsContainer}>
              {colorPicker && (
                <ChromePicker
                  color={hex}
                  onChangeComplete={handleColorChange}
                />
              )}
            </div>
            <div style={styles.multiInputsContainer}>
              {showFontSizeSlider && (
                <div
                  style={{
                    display: 'flex',
                    margin: '0',
                    padding: '0',
                  }}
                >
                  <h5
                    style={{
                      margin: '0',
                      marginTop: '5px',
                      padding: '5px 5px',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    Font Size:
                  </h5>
                  <input
                    style={{
                      border: '1px solid',
                      borderRadius: '5px',
                      marginTop: '5px',
                      width: '70px',
                      padding: '5px 5px',
                    }}
                    onChange={(evt) => {
                      handleTextSizeChange(evt);
                    }}
                    type='number'
                  />
                </div>
              )}
            </div>
          </div>
        </Draggable>
      )}
    </>
  );
};

export default Text;
