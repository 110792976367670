import React, { useState, useEffect } from 'react';
import '../css/productCard.css';
import { useDrag } from 'react-dnd';
import ProductDetailModal from '../modals/ProductDetailModal';
import noImg from './images/no-image.jpg';

const ProductCard = (props) => {
  const [showProductDetail, setShowProductDetail] = useState(false);
  const [restockWithinMonth, setRestockWithinMonth] = useState(0);
  const [productIsRestocked, setProductIsRestocked] = useState(false);
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'product-card',
      product: props.item,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (props.item && props.item.stockQty <= 0) {
      const dateNow = new Date();
      const restockDate = new Date(props.item.restockDate);
      const diffTime = Math.abs(restockDate - dateNow);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays <= 30 && restockDate > dateNow) {
        setRestockWithinMonth(diffDays);
      }
      if (restockDate < dateNow) {
        setProductIsRestocked(true);
      }
    }
  }, []);

  const handleClose = () => {
    setShowProductDetail(!showProductDetail);
  };

  return (
    <>
      <div
        ref={drag}
        className='product-card'
        style={{ opacity: isDragging ? '0.5' : '1' }}
      >
        <div className='product-card-top'>
          <p>{props.item.itemName}</p>
          <p style={{ color: '#B8C5D3', fontWeight: 'bold' }}>
            {props.item.vendorName}
          </p>
          <div className='product-card-imageContainer'>
            <img
              src={props.item.imageURLs[0] ? props.item.imageURLs[0] : noImg}
              alt='Avatar'
              style={{ height: '60px' }}
            />
          </div>

          <h5
            style={{
              color: '#354F8B',
              fontSize: '12px',
              padding: '0',
              margin: '0',
            }}
          >
            {props.item.tradeCurrency !== props.projectCurrency
              ? `$${(props.item.MAP * props.exchangeRate).toFixed(2)} ` +
                props.projectCurrency
              : `$${props.item.MAP} ` + props.item.tradeCurrency}
          </h5>
          {!restockWithinMonth > 0 && !productIsRestocked && (
            <p
              style={{
                color: props.item.stockQty > 0 ? '#31C889' : 'red',
                marginTop: '5px',
              }}
            >
              {`${props.item.stockQty} in stock as of ${
                props.item.createdOn && props.item.createdOn.split('T')[0]
              }`}
            </p>
          )}

          {restockWithinMonth > 0 && !productIsRestocked && (
            <p
              style={{
                color: '#3F51B5',
                marginTop: '5px',
              }}
            >
              {`${restockWithinMonth} day(s) until restock`}
            </p>
          )}

          {productIsRestocked && (
            <p
              style={{
                color: '#31C889',
                marginTop: '5px',
              }}
            >
              {props.item.restockDate &&
                `Restocked on ${props.item.restockDate.split('T')[0]}`}
            </p>
          )}
        </div>

        <div className='product-container-dimension'>
          <button onClick={() => setShowProductDetail(!showProductDetail)}>
            Details
          </button>
        </div>
      </div>
      {showProductDetail && (
        <ProductDetailModal
          handleClose={handleClose}
          item={props.item}
          rate={props.exchangeRate}
        />
      )}
    </>
  );
};

export default ProductCard;
