import { createStore } from 'redux';

let reducer = (state, action) => {
  if (action.type === 'set-Project') {
    return {
      ...state,
      project: action.value,
      projectTitle: action.value.project.title,
    };
  }

  if (action.type === 'set-Products') {
    return {
      ...state,
      products: action.value,
    };
  }

  if (action.type === 'set-itemsPerCategory') {
    return {
      ...state,
      itemPerCategory: action.value,
    };
  }

  if (action.type === 'set-unitFocus') {
    return {
      ...state,
      unit: action.value,
      budget: action.value.unit.fields['Price (USD)'],
    };
  }

  if (action.type === 'set-moodboardData') {
    let unitName = action.unit;
    let tmp = Object.assign({}, state.moodboardData);
    tmp[unitName] = action.value;

    return {
      ...state,
      moodboardData: tmp,
    };
  }

  if (action.type === 'initialize-moodboardData') {
    return {
      ...state,
      moodboardData: action.value,
      date: action.date,
      revisionNum: action.revisionNum,
    };
  }

  if (action.type === 'set-revised-moodboardData') {
    return {
      ...state,
      revisedMoodboardData: action.value,
    };
  }

  if (action.type === 'set-revision') {
    return {
      ...state,
      revision: action.value,
    };
  }

  if (action.type === 'set-projectFiles') {
    return {
      ...state,
      projectFiles: action.value,
    };
  }

  if (action.type === 'set-user') {
    return {
      ...state,
      user: action.value,
    };
  }

  if (action.type === 'set-exchangeRate') {
    return {
      ...state,
      exchangeRate: action.value,
    };
  }

  return state;
};

const store = createStore(
  reducer,
  {
    exchangeRate: null,
    user: null,
    revisionNum: null,
    projectFiles: [],
    revision: false,
    date: null,
    project: null,
    unit: null,
    projectTitle: '',
    budget: 0,
    rooms: null,
    products: null,
    moodboardData: {},
    revisedMoodboardData: {},
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
