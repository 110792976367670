import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StyleCard from './StyleCard';
import '../../css/styleCard.css';

const UnconnectedDocumentsContainer = ({ project, unit }) => {
  const [designStyles, setDesignStyles] = useState(null);

  useEffect(() => {
    if (project && unit) {
      project.quoteData.forEach((data) => {
        if (data.unit.fields['Item Name'] === unit.unit.fields['Item Name']) {
          setDesignStyles(data.designStyles);
        }
      });
    }
  }, [unit]);

  return (
    <>
      <div className='style-container'>
        {designStyles && designStyles.length > 0 ? (
          <>
            {designStyles.map((style) => {
              return <StyleCard styleData={style.fields} />;
            })}
          </>
        ) : (
          <h3>No styles</h3>
        )}
      </div>
    </>
  );
};
let mapStateToProps = (state) => {
  return {
    project: state.project,
    unit: state.unit,
  };
};
let DocumentsContainer = connect(mapStateToProps)(
  UnconnectedDocumentsContainer
);

export default withRouter(DocumentsContainer);
