import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../css/gridFooter.css';
import Button from '../../reusbleComponents/Button';

const UnconnectedGridFooter = (props) => {
  const handleSubmit = () => {
    props.history.push('/');
  };

  return (
    <div className='grid-footer'>
      <Button
        background='#FF4B30'
        borderRadius='100px'
        width='30%'
        enabled={true}
        onClick={() => {
          handleSubmit();
        }}
        color='white'
        label='Back'
      />
    </div>
  );
};
let mapStateToProps = (state) => {
  return {
    moodboardData: state.moodboardData,
  };
};
let GridFooter = connect(mapStateToProps)(UnconnectedGridFooter);

export default withRouter(GridFooter);
