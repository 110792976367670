import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './css/NavDropDown.css';

const useStyles = makeStyles((theme) => ({}));

const UnconnectedNavDropDown = (props) => {
  const classes = useStyles();
  const [unit, setUnit] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (props.units.length > 0) {
      if (props.unit === null) {
        setUnit(0);
        props.dispatch({
          type: 'set-unitFocus',
          value: props.units[0],
        });
      } else {
        const index = props.units.findIndex(
          (unit) =>
            unit.unit.fields['Item Name'] ===
            props.unit.unit.fields['Item Name']
        );
        setUnit(index);
        props.dispatch({
          type: 'set-unitFocus',
          value: props.units[index],
        });
      }
    }
  }, []);

  const handleChange = (event) => {
    setUnit(event.target.value);

    let selectedUnit = props.units[event.target.value];

    props.dispatch({
      type: 'set-unitFocus',
      value: selectedUnit,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div style={{ float: 'right' }}>
      <FormControl className={classes.formControl}>
        {unit >= 0 && (
          <Select
            labelId='navDropDown-label'
            id='navDropDown-select'
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={unit}
            onChange={handleChange}
            disableUnderline={true}
          >
            {props.units &&
              props.units.map((elm, index) => (
                <MenuItem key={index} value={index}>
                  {elm.unit.fields['Item Name']}
                </MenuItem>
              ))}
          </Select>
        )}
      </FormControl>
    </div>
  );
};
let mapStateToProps = (state) => {
  return {
    project: state.project,
    totalPrice: state.totalPrice,
    unit: state.unit,
  };
};
let NavDropDown = connect(mapStateToProps)(
  UnconnectedNavDropDown
);

export default NavDropDown;
