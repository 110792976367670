import React, { useEffect, useState } from 'react';
import NavBar from '../NavBar';
import ProductContainer from '../products/ProductContainer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CategorySelectButton from './inputComponents/CategorySelectButton';
import MoodBoard from './MoodBoard';
import Footer from '../Footer';
import '../css/moodboard.css';
import closeIcon from './images/close.png';
import menuIcon from './images/menu.png';

const UnconnectedMoodboardContainer = (props) => {
  const [selectedCategory, setSelectedCategory] = useState(
    ''
  );
  const [categoryData, setCategoryData] = useState(null);
  const [
    moodboardRoomList,
    setmoodboardRoomList,
  ] = useState(null);
  const [collapseSideBar, setCollapseSideBar] = useState(
    false
  );
  const [showVendorItems, setShowVendorItems] = useState(
    true
  );

  useEffect(() => {
    if (!props.project) {
      props.history.push('/start/undefined');
    }
  }, []);

  useEffect(() => {
    let roomList = {};
    let selectedUnit = '';
    if (props.unit) {
      selectedUnit = props.unit.unit.fields['Item Name'];
      props.unit.roomList.forEach(
        (room) =>
          (roomList[room.description] = {
            moodboard: [],
            meta: {},
            text: {},
          })
      );
      if (props.moodboardData[selectedUnit]) {
        let tmp = Object.assign(
          {},
          props.moodboardData[selectedUnit]
        );
        props.dispatch({
          type: 'set-moodboardData',
          value: tmp,
          unit: selectedUnit,
        });
        setmoodboardRoomList(tmp);
        return;
      }
      props.dispatch({
        type: 'set-moodboardData',
        value: roomList,
        unit: selectedUnit,
      });
      setmoodboardRoomList(roomList);
    }
  }, [props.unit]);

  const handleCategoryClick = async (evt, data) => {
    let category = evt;
    setSelectedCategory(category);
    setCategoryData(data);
  };

  const renderCategorySelectButton = (
    category,
    index,
    roomType
  ) => {
    return (
      <li
        key={index}
        style={{ display: 'flex', paddingLeft: '10px' }}
      >
        <CategorySelectButton
          key={index}
          label={category.fields['Item Name']}
          itemPerCategory={category.fields}
          active={
            selectedCategory ===
            category.fields['Item Name']
          }
          onClick={(evt) =>
            handleCategoryClick(evt, category.fields)
          }
          itemQty={
            category.fields[props.unit.unit.fields.SKU]
          }
          roomType={roomType}
        />
      </li>
    );
  };

  return (
    <>
      <NavBar />
      <div className='moodboard-container'>
        <div
          className='moodboard-container-collapsed'
          style={{ display: !collapseSideBar && 'none' }}
        >
          <div>
            <img
              width='20px'
              src={menuIcon}
              alt='close-icon'
              onClick={(e) => setCollapseSideBar(false)}
            />
          </div>
        </div>

        <div
          className='moodboard-container-left'
          style={{ display: collapseSideBar && 'none' }}
        >
          <div>
            <div
              style={{
                padding: '10px',
                textAlign: 'right',
              }}
            >
              <img
                width='15px'
                src={closeIcon}
                alt='close-icon'
                onClick={(e) => setCollapseSideBar(true)}
              />
            </div>
            <h5
              style={{
                borderBottom: '0.5px solid #B8C5D3',
                padding: '10px',
                margin: ' 0 10px 0 0',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {props.unit &&
                props.unit.unit.fields['Item Name']}
            </h5>
          </div>

          {props.unit &&
            props.unit.quoteItems.map((elm, index) => (
              <>
                <h5
                  key={index}
                  style={{ margin: '20px 0 0 10px' }}
                >
                  {elm.room.description}
                </h5>
                {elm.items.map((category, index) =>
                  renderCategorySelectButton(
                    category,
                    index,
                    elm.room.description
                  )
                )}
              </>
            ))}
        </div>

        <div className='moodboard-container-right'>
          <h5
            style={{
              position: 'fixed',
              marginTop: '10px',
              display: showVendorItems && 'none',
            }}
            onClick={(e) => setShowVendorItems(true)}
          >
            + Show Vendor Items
          </h5>
          <div>
            <div
              style={{
                width: '35%',
                margin: '0 10px',
                marginTop: '0px',
                zIndex: '100',
                display: !showVendorItems && 'none',
              }}
            >
              <h5
                style={{
                  position: 'fixed',
                  display: !showVendorItems && 'none',
                  textAlign: 'right',
                }}
                onClick={(e) => setShowVendorItems(false)}
              >
                - Collapse Vendor Items
              </h5>
              <ProductContainer
                category={selectedCategory}
                categoryData={categoryData}
              />
            </div>

            <div
              style={{
                flexGrow: '1',
                margin: '0 10px',
              }}
              disabled={true}
            >
              {moodboardRoomList && (
                <MoodBoard roomList={moodboardRoomList} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
let mapStateToProps = (state) => {
  return {
    project: state.project,
    projectTitle: state.projectTitle,
    unit: state.unit,
    revisedMoodboardData: state.revisedMoodboardData,
    moodboardData: state.moodboardData,
  };
};
let MoodboardContainer = connect(mapStateToProps)(
  UnconnectedMoodboardContainer
);
export default withRouter(MoodboardContainer);
