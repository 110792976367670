import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FETCH_URL } from '../../../constants';
import { FaFileDownload } from 'react-icons/fa';
import Avatar from '@material-ui/core/Avatar';

const UnconnectedDocumentsContainer = ({ project, dispatch }) => {
  const [files, setFiles] = useState(null);
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    const getDocs = async () => {
      let response = await fetch(
        `${FETCH_URL}/pipedrive/files/${project.project.id}`,
        {
          method: 'GET',
          credentials: 'include',
          // headers: {
          //   'Content-Type': 'application/json',
          //   Accept: 'application/json',
          // },
        }
      );

      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);

      if (payload.success) {
        setUrls(payload.files);
        let _data = await getData(payload.files);
        setFiles(_data);
        dispatch({
          type: 'set-projectFiles',
          value: _data,
        });
      }
    };

    if (project) {
      getDocs();
    }
  }, []);

  const getData = async (files) => {
    return Promise.all(
      files.map((file, index) => getDocsArray(file.id, files, index))
    );
  };

  const getDocsArray = (id, files, index) => {
    return new Promise(async (resolve, reject) => {
      let response = await fetch(
        `${FETCH_URL}/pipedrive/files/download/${id}`,
        {
          method: 'GET',
          credentials: 'include',
          // headers: {
          //   'Content-Type': 'application/json',
          //   Accept: 'application/json',
          // },
        }
      );

      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);
      if (payload.success) {
        resolve({ url: payload.fileUrl, filename: files[index].name });
      } else {
        resolve('');
      }
    });
  };

  return (
    <>
      {files ? (
        <div className='docs-container'>
          <ul style={{ listStyle: 'none' }}>
            {files.map((file, index) => {
              return (
                <li key={index}>
                  <div style={{ display: 'flex', margin: '10px' }}>
                    <Avatar>
                      <FaFileDownload />
                    </Avatar>
                    <button
                      style={{
                        borderRadius: '5px',
                        padding: '5px 5px',
                        border: 'none',
                      }}
                      onClick={() => window.open(file.url, '_blank')}
                    >
                      {urls[index].file_name}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};
let mapStateToProps = (state) => {
  return {
    project: state.project,
  };
};
let DocumentsContainer = connect(mapStateToProps)(
  UnconnectedDocumentsContainer
);

export default withRouter(DocumentsContainer);
