import React, { useEffect, useState } from "react";
import { FETCH_URL } from "../../../constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import BackgroundUploadModal from "../../modals/BackgroundUploadModal";
import PropsCard from "./PropsCard";
import { useAlert } from "react-alert";
import "../../css/BackgroundPropsContainer.css";

export default function BackgroundPropsContainer() {
  const [moodboardProps, setMoodBoardProps] = useState(null);
  const [backgroundModal, setBackgroundModal] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    getMoodboardProps();
  }, []);

  const getMoodboardProps = async () => {
    setLoading(true);

    let response = await fetch(`${FETCH_URL}/moodboard-property/background`, {
      method: "GET",
      credentials: "include",
      // headers: {
      //   'Content-Type': 'application/json',
      //   Accept: 'application/json',
      // },
    });

    let responseBody = await response.text();
    let payload = JSON.parse(responseBody);

    if (payload.success && payload.moodboardPropertyData) {
      setLoading(false);
      setMoodBoardProps(payload.moodboardPropertyData);
    } else {
      setLoading(false);
      setMoodBoardProps(null);
    }
  };

  const handleModalSubmit = async (imageUpload, inputs) => {
    setImageUploadLoading(true);
    let data = new FormData();
    data.append("description", inputs.description);
    data.append("title", "background");
    data.append("file", imageUpload.file);
    let response = await fetch(`${FETCH_URL}/moodboard-property`, {
      method: "POST",
      credentials: "include",
      body: data,
    });

    let responseBody = await response.text();
    let payload = JSON.parse(responseBody);

    if (payload.success) {
      alert.show("success");
      getMoodboardProps();
      handleClose();
      setImageUploadLoading(false);
    } else {
      alert.show("Something went wrong: " + payload.message);
      handleClose();
      setImageUploadLoading(false);
    }
  };

  const removeProps = async (id) => {
    let response = await fetch(`${FETCH_URL}/moodboard-property/${id}`, {
      method: "delete",
      credentials: "include",
    });
    let responseBody = await response.text();
    let payload = JSON.parse(responseBody);
    if (payload.success) {
      getMoodboardProps();
    } else {
      alert.show("Something went wrong: " + payload.message);
    }
  };

  const handleClose = () => {
    setBackgroundModal(!backgroundModal);
  };

  return (
    <div className="backgroundProps-container">
      <div className="backgroundProps-button-container">
        <button
          className="backgroundProps-button"
          onClick={() => {
            setBackgroundModal(true);
          }}
        >
          Add Background Props
        </button>
      </div>
      <div className="backgroundProps-item-count">
        {moodboardProps && `Items: ${moodboardProps.length}`}
      </div>

      <div className="backgroundProps-card-container">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {moodboardProps &&
              moodboardProps.map((item) => {
                return (
                  <PropsCard item={item} removeProp={(id) => removeProps(id)} />
                );
              })}
          </>
        )}
      </div>
      {backgroundModal && (
        <BackgroundUploadModal
          handleSubmit={(description, imageUplaod) =>
            handleModalSubmit(description, imageUplaod)
          }
          handleClose={handleClose}
          loading={imageUploadLoading}
        />
      )}
    </div>
  );
}
