import React, { useEffect } from 'react';

import useContextMenu from './UseContextMenu';

const Menu = (props) => {
  const { xPos, yPos, menu } = useContextMenu(props.outerRef);

  if (menu) {
    return (
      <div style={{ top: yPos, left: xPos, zIndex: '1000' }} className='menu'>
        <div className='menu--option' onClick={props.duplicate}>
          Duplicate
        </div>
        <div className='menu--option' onClick={props.flipImg}>
          Flip Image
        </div>
        <div className='menu--option' onClick={props.showRotateImg}>
          Rotate Image
        </div>
        <div className='menu--option' onClick={props.cropImage}>
          Crop Image
        </div>
        {!props.item.bkgImageURL && (
          <div className='menu--option' onClick={props.setAspectRatio}>
            {props.item && props.item.moodboardMeta.aspectRatio
              ? 'Unlock aspect ratio'
              : 'Lock aspect ratio'}
          </div>
        )}
        {props.item.vendorName && (
          <div
            className='menu--option'
            onClick={() => {
              props.removeBG(props.item, props.roomType);
            }}
          >
            Remove Background
          </div>
        )}
        <div className='menu--separator' />
        {props.item.vendorName && !props.item.airtableProduct && (
          <div
            className='menu--option'
            onClick={() => {
              props.details(props.item);
            }}
          >
            Details
          </div>
        )}
        {props.item.airtableProduct && (
          <div
            className='menu--option'
            onClick={() => {
              props.airtableDetails(props.item);
            }}
          >
            Details
          </div>
        )}
        <div className='menu--separator' />
        {props.imgArray &&
          props.imgArray.map((img, index) => {
            return (
              <div
                className='menu--option'
                style={{
                  backgroundColor:
                    img === props.item.displayedImage ? 'green' : '',
                }}
                onClick={() => {
                  props.setImg(index, props.item.moodboardId);
                }}
              >
                {`Image ${index + 1} ${
                  img.includes('moodboard-image.s3') ? '*' : ''
                }`}
              </div>
            );
          })}
        <div className='menu--separator' />

        <div
          className='menu--option'
          onClick={() => {
            props.delete(props.item, props.roomType);
          }}
        >
          Delete
        </div>
      </div>
    );
  }
  return <></>;
};

export default Menu;
