import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import LandingPage from './components/LandingPage';
import MoodBoardContainer from './components/moodboard/MoodboardContainer';
import GridViewContainer from './components/gridView/GridViewContainer';
import ClientViewContainer from './components/gridView/moodboard-clientView/ClientViewContainer';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

const UnconnectedApp = ({ lgin, dispatch }) => {
  return (
    <>
      <BrowserRouter>
        <DndProvider backend={HTML5Backend}>
          <Switch>
            <Route
              exact={true}
              path='/start/:projectTitle'
              component={LandingPage}
            ></Route>
            <Route
              exact={true}
              path='/gridView'
              component={GridViewContainer}
            ></Route>
            <Route
              exact={true}
              path='/clientView/:quoteID'
              component={ClientViewContainer}
            ></Route>
            <Route exact={true} path='/' component={MoodBoardContainer}></Route>
          </Switch>
        </DndProvider>
      </BrowserRouter>
    </>
  );
};

let mapStateToProps = (state) => {
  return { unit: state.unit };
};
let App = connect(mapStateToProps)(UnconnectedApp);

export default App;
