import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FaCheck } from 'react-icons/fa';
import ReactCrop from 'react-image-crop';
import Menu from '../contextMenu/Menu';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import 'react-image-crop/dist/ReactCrop.css';
import { FETCH_URL } from '../../../constants';
import useKey from '../hooks/useKey';
import '../../css/context-menu.css';

export default function ImageOnCanvas(props) {
  let imgCrop = useRef(null);
  const imageRef = useRef(null);
  const [crop, setCrop] = useState({});
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [imgBlob, setImgBlob] = useState(null);
  const [rotation, setRotation] = useState(
    props.item.moodboardMeta.rotation || 0
  );
  const [showRotate, setShowRotate] = useState(false);
  const enterKey = useKey('enter');
  const escKey = useKey('Escape');
  const [enterBtn, setEnterBtn] = useState(false);

  useEffect(() => {
    const handleCropImg = async () => {
      let newImgURL = await sendCropImgToAWS(imgBlob);
      props.setCropImg(newImgURL, props.item.moodboardId);
      setShowCrop(false);
      props.setCroppingMode(props.item.moodboardId, false);
    };
    if (showCrop && imgBlob) {
      handleCropImg();
    }
  }, [enterKey, enterBtn]);

  useEffect(() => {
    setShowRotate(false);
    setShowCrop(false);
    props.setCroppingMode(props.item.moodboardId, false);
  }, [escKey]);

  useEffect(() => {
    setCrop({});
    const getBase64 = async () => {
      let base64 = await imageToBase64(props.url);
      setImgSrc(base64);
    };
    if (!props.url.includes('blob')) {
      getBase64();
    } else {
      setImgSrc(props.url);
    }
  }, [props.url, props.roomType]);

  const sendCropImgToAWS = (_blob) => {
    return new Promise(async (resolve, reject) => {
      let data = new FormData();
      data.append('title', props.item.itemName);
      data.append('file', _blob);
      let response = await fetch(`${FETCH_URL}/images`, {
        method: 'POST',
        credentials: 'include',
        body: data,
      });

      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);

      if (payload.success) {
        resolve(payload.newImgURL);
      } else {
        console.log('ERROR', payload.message);
      }
    });
  };

  const imageToBase64 = async (url) => {
    return new Promise(async (resolve, reject) => {
      let response = await fetch(
        `${FETCH_URL}/imageToBase64?url=${JSON.stringify(url)}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );

      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);

      if (payload.success) {
        resolve(payload.imageBase64);
      } else {
        resolve(null);
      }
    });
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onImageLoaded = useCallback((img) => {
    imgCrop.current = img;
  }, []);

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imgCrop.current && crop.width && crop.height) {
      getCroppedImg(imgCrop.current, crop, 'newFile.jpeg');
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        setImgBlob(blob);
        window.URL.revokeObjectURL(croppedImageUrl);
        setCroppedImageUrl(window.URL.createObjectURL(blob));
      }, 'image/jpeg');
    });
  };

  const handleRotateChange = (event, newValue) => {
    let rotateVal = event.target.value ? event.target.value : newValue;
    setRotation(rotateVal);
    let targetList = props.list[props.roomType].moodboard;
    let index = targetList.findIndex(
      (i) => i.moodboardId === props.item.moodboardId
    );
    targetList[index].moodboardMeta.rotation = rotateVal;
    props.onListChange(targetList, props.roomType);
  };

  const handleFlipImg = () => {
    let targetList = props.list[props.roomType].moodboard;
    let index = targetList.findIndex(
      (item) => item.moodboardId === props.item.moodboardId
    );
    targetList[index].moodboardMeta.flipImg = !props.item.moodboardMeta.flipImg;
    props.onListChange(targetList, props.roomType);
  };

  const duplicateItem = () => {
    let targetList = props.list[props.roomType].moodboard;
    let index = targetList.findIndex(
      (item) => item.moodboardId === props.item.moodboardId
    );

    let _duplicateItem = {
      ...targetList[index],
      moodboardId: Date.now(),
      moodboardMeta: {
        ...targetList[index].moodboardMeta,
        position: {
          top: targetList[index].moodboardMeta.position.top + 20,
          left: targetList[index].moodboardMeta.position.left + 20,
          zIndex: 0,
        },
      },
    };

    targetList.push(_duplicateItem);
    props.onListChange(targetList, props.roomType);
  };

  const handleAspectRatio = () => {
    let targetList = props.list[props.roomType].moodboard;
    let index = targetList.findIndex(
      (item) => item.moodboardId === props.item.moodboardId
    );
    targetList[index].moodboardMeta.aspectRatio = !props.item.moodboardMeta
      .aspectRatio;
    props.onListChange(targetList, props.roomType);
  };

  return (
    <>
      {imgSrc ? (
        <div className='overlay-image'>
          {!showCrop && (
            <>
              <img
                ref={imageRef}
                className='image'
                style={{
                  ...props.style,
                  transform: `rotate(${rotation}deg) ${
                    props.item.moodboardMeta.flipImg ? `scaleX(-1)` : ''
                  }`,
                }}
                src={props.url}
                draggable='false'
                alt=''
              />
              {showRotate && (
                <div>
                  <Typography id='continuous-slider' gutterBottom>
                    Rotate
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Slider
                        value={rotation}
                        onChange={handleRotateChange}
                        valueLabelDisplay='auto'
                        aria-labelledby='discrete-slider-custom'
                        max={360}
                      />
                    </Grid>
                    <Grid item>
                      <button
                        style={{
                          borderRadius: '5px',
                          backgroundColor: 'white',
                        }}
                        onClick={() => {
                          setShowRotate(false);
                          props.setCroppingMode(props.item.moodboardId, false);
                        }}
                      >
                        X
                      </button>
                    </Grid>
                    <Grid item xs>
                      <input
                        type='number'
                        min='0'
                        max='360'
                        onChange={(evt) => {
                          handleRotateChange(evt, null);
                        }}
                      ></input>
                    </Grid>
                  </Grid>
                </div>
              )}
            </>
          )}
          {showCrop && (
            <div ref={imageRef} className='overlay-image'>
              <button
                style={{
                  borderRadius: '5px',
                  backgroundColor: 'white',
                  marginBottom: '10px',
                }}
                onClick={() => {
                  setShowCrop(false);
                  props.setCroppingMode(props.item.moodboardId, false);
                }}
              >
                X
              </button>
              <button
                style={{
                  borderRadius: '5px',
                  backgroundColor: 'white',
                  marginLeft: '5px',
                  marginBottom: '10px',
                }}
                onClick={() => {
                  setEnterBtn(!enterBtn);
                }}
              >
                <FaCheck />
              </button>
              <ReactCrop
                src={imgSrc}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
              />
            </div>
          )}
          <Menu
            outerRef={imageRef}
            showRotateImg={() => {
              setShowRotate(!showRotate);
              props.setCroppingMode(props.item.moodboardId, true);
            }}
            setImg={props.setImg}
            delete={props.delete}
            imgArray={props.imgArray}
            details={props.detailsModal}
            airtableDetails={props.airtableDetailsModal}
            removeBG={props.removeBG}
            item={props.item}
            roomType={props.roomType}
            duplicate={() => {
              duplicateItem();
            }}
            cropImage={() => {
              setShowCrop(!showCrop);
              props.setCroppingMode(props.item.moodboardId, true);
            }}
            setAspectRatio={() => {
              handleAspectRatio();
            }}
            flipImg={() => {
              handleFlipImg();
            }}
          />
        </div>
      ) : (
        <img
          ref={imageRef}
          className='image'
          style={{
            ...props.style,
            transform: `rotate(${rotation}deg) ${
              props.item.moodboardMeta.flipImg ? `scaleX(-1)` : ''
            }`,
          }}
          src={props.url}
          draggable='false'
          alt=''
        />
      )}
    </>
  );
}
