import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '../../reusbleComponents/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(92,96,118,.75)',
    boxShadow: 'none',
    fontSize: '10px',
  },
  tableRow: {
    fontSize: '10px',
  },
  paper: {
    width: '500px',
    height: '500px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: '0',
    padding: theme.spacing(2, 4, 3),
    fontSize: '10px',
  },
  table: {
    minWidth: 400,
    minHeight: 500,
    border: 'none',
    fontSize: '10px',
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const UnconnectedProductDetailModal = (props) => {
  function createData(name, data) {
    if (data === undefined || data === null) {
      return { name, data: 'ERROR!' };
    }
    if (name === 'Image URL' && data !== null) {
      let link = data;
      return { name, link };
    }
    if (name === 'Description' && data !== null) {
      return { name, data, type: 'description' };
    }
    return { name, data };
  }

  const rows = [
    createData('Name', `${props.item.itemName}`),
    createData('SKU', props.item.SKU),
    createData('Fulhaus Category', props.item.fulhausCategory),
    createData('Vendor Name', props.item.vendorName),
    createData('Image URL', props.item.imageURLs[0]),
    createData('Weight', `${props.item.weight}${props.item.weightUnit}`),
    createData(
      'Dimension',
      `${props.item.dimension} ${props.item.dimensionUnit}`
    ),
    createData(
      'MAP',
      `${props.item.MAP.toFixed(2)} ${
        props.canvasTrigger
          ? props.project.projectCurrency
          : props.item.tradeCurrency
      }${
        props.item.tradeCurrency !== props.project.projectCurrency &&
        props.rate &&
        !props.canvasTrigger
          ? ` --> $${(props.item.MAP * props.rate).toFixed(2)} ` +
            props.project.projectCurrency
          : ''
      }`
    ),
    createData(
      'Trade Price',
      `${props.item.tradePrice.toFixed(2)} ${
        props.canvasTrigger
          ? props.project.projectCurrency
          : props.item.tradeCurrency
      }  ${
        props.item.tradeCurrency !== props.project.projectCurrency &&
        props.rate &&
        !props.canvasTrigger
          ? ` --> $${(props.item.tradePrice * props.rate).toFixed(2)} ` +
            props.project.projectCurrency
          : ''
      }`
    ),
    createData(
      'MSRP',
      `${props.item.MSRP.toFixed(2)} ${
        props.canvasTrigger
          ? props.project.projectCurrency
          : props.item.tradeCurrency
      }${
        props.item.tradeCurrency !== props.project.projectCurrency &&
        props.rate &&
        !props.canvasTrigger
          ? ` --> $${(props.item.MSRP * props.rate).toFixed(2)} ` +
            props.project.projectCurrency
          : ''
      }`
    ),
    createData(
      'Stock',
      `${props.item.stockQty} in stock as of ${
        props.item.createdOn.split('T')[0]
      } ${props.item.createdOn.split('T')[1].substring(0, 8)}`
    ),
    createData(
      'Restock',
      `${
        props.item.restockDate
          ? props.item.restockDate.split('T')[0]
          : 'no restock date available'
      }`
    ),
    createData(
      'Vendor Product Category',
      `${props.item.vendorProductCategory}`
    ),
    createData('Color', `${props.item.color}`),
    createData('Material', `${props.item.material}`),
    createData('Description', `${props.item.vendorItemDescription}`),
  ];

  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={true}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <div className='productDetail-container'>
            <div className='productDetail-button-container'>
              <Button
                background='white'
                borderRadius='100px'
                width='10%'
                enabled={true}
                onClick={props.handleClose}
                color='grey'
                label='X'
              />
            </div>
            <div className='productDetail'>
              <div className='productDetail-left'>
                <Carousel width={'450px'} showArrows={true} showThumbs={false}>
                  {props.item.imageURLs.map((url) => {
                    return (
                      <img
                        alt='product-img'
                        style={{ width: '450px' }}
                        src={url}
                      />
                    );
                  })}
                </Carousel>
              </div>
              <div className='productDetail-right'>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label='customized table'
                  >
                    <TableBody>
                      {rows.map((row) => (
                        <StyledTableRow
                          className={classes.tableRow}
                          key={row.name}
                        >
                          <StyledTableCell component='th' scope='row'>
                            {row.name}
                          </StyledTableCell>
                          {row.data ? (
                            <StyledTableCell
                              align={
                                row.type === 'description' ? 'left' : 'left'
                              }
                            >
                              {row.data}
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell align='left'>
                              <a
                                target='_blank'
                                href={row.link}
                                rel='noopener noreferrer'
                              >
                                LINK
                              </a>
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    project: state.project,
  };
};
let ProductDetailModal = connect(mapStateToProps)(
  UnconnectedProductDetailModal
);

export default ProductDetailModal;
