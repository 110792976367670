import React, { useState, useEffect } from 'react';
import '../../css/productCard.css';
import { useDrag } from 'react-dnd';
import AirtableProductModal from '../../modals/AirtableProductModal';

const AirtableProductCard = (props) => {
  const [showProductDetail, setShowProductDetail] = useState(false);
  const [clippedWithInDate, setClippedWithInDate] = useState(false);
  const [daysClipped, setDaysClipped] = useState(0);
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'product-card',
      product: props.item,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (props.item) {
      const dateNow = new Date();
      const clippedDate = new Date(props.item.createdTime);
      const diffTime = Math.abs(clippedDate - dateNow);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDaysClipped(diffDays);

      if (diffDays <= 30) {
        setClippedWithInDate(true);
      }
    }
  }, []);

  const handleClose = () => {
    setShowProductDetail(!showProductDetail);
  };

  return (
    <>
      <div
        ref={drag}
        className='product-card'
        style={{ opacity: isDragging ? '0.5' : '1' }}
      >
        <div className='product-card-top'>
          {props.item.itemName.length > 30 ? (
            <p>{props.item.itemName.substring(0, 30)}</p>
          ) : (
            <p>{props.item.itemName}</p>
          )}

          <p style={{ color: '#B8C5D3', fontWeight: 'bold' }}>
            {props.item.vendorName}
          </p>
          <p>
            {props.item.vendorLocation !== 'unavailable' &&
              props.item.vendorLocation}
          </p>
          <div className='product-card-imageContainer'>
            {props.item.imageURLs && (
              <img src={props.item.imageURLs[0].url} alt='Avatar' />
            )}
          </div>
          <h5
            style={{
              color: '#354F8B',
              fontSize: '12px',
              padding: '0',
              margin: '0',
            }}
          >
            {props.projectCurrency.includes('CAD')
              ? `$${props.item.priceCAD.toFixed(2)} CAD`
              : ''}
            {props.projectCurrency.includes('USD')
              ? `$${props.item.priceUSA.toFixed(2)} USD`
              : ''}
          </h5>
          <div className='product-card-airtable-days-container'>
            <p
              style={{ color: clippedWithInDate ? '#31C889' : 'red' }}
            >{`Clipped ${daysClipped} day(s) ago`}</p>
          </div>
        </div>

        <div className='product-container-dimension'>
          <button onClick={() => setShowProductDetail(!showProductDetail)}>
            Details
          </button>
        </div>
      </div>
      {showProductDetail && (
        <AirtableProductModal handleClose={handleClose} item={props.item} />
      )}
    </>
  );
};

export default AirtableProductCard;
