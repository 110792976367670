import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import InputBox from '../../reusbleComponents/InputBox';
import { withRouter } from 'react-router-dom';
import { FETCH_URL } from '../../constants';
import { useAlert } from 'react-alert';
import Button from '../../reusbleComponents/Button';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(92,96,118,.75)',
    boxShadow: 'none',
  },
  paper: {
    width: '500px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: '0',
    padding: theme.spacing(2, 4, 3),
  },
}));

const UnconnectedSwitchProjectModal = (props) => {
  const alert = useAlert();
  const classes = useStyles();
  const [listingID, setListingId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let roomList = {};
    let selectedUnit = '';
    if (props.unit) {
      selectedUnit = props.unit.unit.fields.SKU;
      props.unit.roomList.forEach(
        (room) =>
          (roomList[room.description] = {
            moodboard: [],
            meta: {},
            text: {},
          })
      );
      if (props.moodboardData[selectedUnit]) {
        let tmp = Object.assign({}, props.moodboardData[selectedUnit]);
        props.dispatch({
          type: 'set-moodboardData',
          value: tmp,
          unit: selectedUnit,
        });

        return;
      }
      props.dispatch({
        type: 'set-moodboardData',
        value: roomList,
        unit: selectedUnit,
      });
    }
  }, [props.unit]);

  const handleSubmit = async () => {
    setLoading(true);
    if (listingID) {
      let response = await fetch(`${FETCH_URL}/quotes/project/${listingID}`, {
        method: 'GET',
        credentials: 'include',
        // headers: {
        //   'Content-Type': 'application/json',
        //   Accept: 'application/json',
        // },
      });
      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);
      if (payload.success && payload.data) {
        props.dispatch({
          type: 'set-listingID',
          value: payload,
        });
        props.dispatch({
          type: 'set-unitFocus',
          value: payload.data.quoteData[0],
        });
        let moodboardData = await loadMoodboard(payload.data._id);
        setLoading(false);
        props.dispatch({
          type: 'initialize-moodboardData',
          value: moodboardData,
        });
        props.handleClose();
        props.history.push('/');
      } else {
        setLoading(false);
        alert.show('Something went wrong: ' + payload.message);
      }
    } else {
      setLoading(false);
      props.handleClose();
      alert.show('Please Enter a project name or ID');
    }
  };

  const loadMoodboard = (quoteID) => {
    let moodboardData = {};
    return new Promise(async (resolve, reject) => {
      let response = await fetch(`${FETCH_URL}/moodboard/${quoteID}`, {
        method: 'GET',
        credentials: 'include',
        // headers: {
        //   'Content-Type': 'application/json',
        //   Accept: 'application/json',
        // },
      });
      let responseBody = await response.text();
      let payload2 = JSON.parse(responseBody);
      if (payload2.success) {
        moodboardData = payload2.moodboard.moodboardData;
      }
      resolve(moodboardData);
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={true}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <div className='switchmodal-container'>
            <div className='landingPage-form'>
              <h1>Change Project</h1>
              <InputBox
                background=' #FFFFFF'
                border=' 1px solid #B8C5D3'
                borderRadius=' 40px'
                width='100%'
                hieght='50px'
                placeholder='Enter a project name or ID'
                onChange={(evt) => {
                  setListingId(evt.target.value);
                }}
              />
              <div className='landingPage-button-container'>
                <Button
                  background='#748AA1'
                  borderRadius='100px'
                  width='50%'
                  enabled={true}
                  onClick={props.handleClose}
                  color='white'
                  label='Cancel'
                />
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    background='#FF4B30'
                    borderRadius='100px'
                    width='50%'
                    enabled={true}
                    onClick={() => {
                      handleSubmit();
                    }}
                    color='white'
                    label='Submit'
                  />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    project: state.project,
  };
};
let SwitchProjectModal = connect(mapStateToProps)(
  UnconnectedSwitchProjectModal
);

export default withRouter(SwitchProjectModal);
