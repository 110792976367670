import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import Canvas from './canvas/Canvas';
import Button from '../../reusbleComponents/Button';
import { FiGrid } from 'react-icons/fi';
import { withRouter } from 'react-router-dom';
import produce from 'immer';
import { FETCH_URL } from '../../constants';
import loadingImg from './images/loading_removebg.gif';
import CircularProgress from '@material-ui/core/CircularProgress';
import OverwriteModal from '../modals/OverwriteModal';
import ClientLinkModal from '../modals/ClientLinkModal';
import useKey from './hooks/useKey';

const ref = React.createRef();

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const UnconnectedMoodBoard = (props) => {
  const [list, setList] = useState(props.roomList);
  const [listHistory, setListHistory] = useState({});
  const [canvasArr, setCanvasArr] = useState(['0']);
  const [airtableLoading, setAirtableLoading] = useState(false);
  const [zoomEnabled, setZoomEnabled] = useState({
    0: true,
  });
  const [showOverwriteModal, setShowOverwriteModal] = useState(false);
  const [showClientLinkModal, setShowClientLinkModal] = useState(false);
  const [clientLink, setClientLink] = useState(null);
  const alert = useAlert();

  useEffect(() => {
    setList(props.roomList);
  }, [props.roomList]);

  const setStateAndStore = (room, action, currentState, prevState) => {
    // let tmpHistory = listHistory;

    // tmpHistory = {
    //   ...tmpHistory,
    //   [room]: {
    //     ...tmpHistory[room],
    //     [`${Date.now()}`]: {
    //       action: action,
    //       data: { prevState: prevState },
    //     },
    //   },
    // };

    // setListHistory(tmpHistory);

    setList(currentState);
    props.dispatch({
      type: 'set-moodboardData',
      value: currentState,
      unit: props.unit.unit.fields['Item Name'],
    });
  };

  const addSourcedItem = async (product, roomType) => {
    let modifiedProduct = {};
    let newArr = list[roomType].moodboard;
    let tmpArr = list[roomType];
    if ((product.MAP || product.bkgImageURL) && !product.airtableProduct) {
      let dims = await getMeta(product.bkgImageURL || product.imageURLs[0]);
      modifiedProduct = {
        ...product,
        MAP: props.exchangeRate
          ? product.MAP * props.exchangeRate
          : product.MAP,
        tradePrice: props.exchangeRate
          ? product.tradePrice * props.exchangeRate
          : product.tradePrice,
        MSRP: props.exchangeRate
          ? product.MSRP * props.exchangeRate
          : product.MSRP,
        displayedImage: product.imageURLs ? product.imageURLs[0] : null,
        moodboardMeta: {
          position: {
            top: product.top || 50,
            left: product.left || 100,
            zIndex: product.zIndex || 0,
          },
          aspectRatio: true,
          dimensions: dims,
          rotation: 0,
          flipImg: false,
        },
        moodboardId: Date.now(),
        isDragging: false,
        isResizing: false,
        isCropping: false,
        ref: null,
      };
    }
    if (product.airtableProduct) {
      let dims = await getMeta(product.imageURLs[0].url);
      let imgArray = await getImgArray(product.imageURLs);
      modifiedProduct = {
        ...product,
        tradePrice: props.project.projectCurrency.includes('CAD')
          ? product.priceCAD
          : product.priceUSA,
        imageURLs: imgArray,
        displayedImage: product.imageURLs ? product.imageURLs[0].url : null,
        moodboardMeta: {
          position: {
            top: product.top || 50,
            left: product.left || 100,
            zIndex: product.zIndex || 0,
          },
          aspectRatio: true,
          dimensions: dims,
          rotation: 0,
          flipImg: false,
        },
        moodboardId: Date.now(),
        isDragging: false,
        isResizing: false,
        isCropping: false,
        ref: null,
      };
    }
    console.log({ modifiedProduct });
    newArr.push(modifiedProduct);
    let newObj = { ...list[roomType], moodboard: newArr };
    setStateAndStore(
      roomType,
      'Add product',
      { ...list, [roomType]: newObj },
      tmpArr
    );
  };

  const getImgArray = (imgArray) => {
    let tmpArray = [];
    return new Promise((resolve, reject) => {
      for (let index = 0; index < imgArray.length; index++) {
        tmpArray.push(imgArray[index].url);
      }
      resolve(tmpArray);
    });
  };

  const getMeta = (url) => {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.addEventListener('load', function () {
        resolve({
          width:
            (this.naturalWidth * 200) /
            (this.naturalWidth + this.naturalHeight),
          height:
            (this.naturalHeight * 200) /
            (this.naturalWidth + this.naturalHeight),
        });
      });
      img.src = url;
    });
  };

  const onRemove = (item, room) => {
    let newList = list[room].moodboard.filter((i) => {
      return i.moodboardId !== item.moodboardId;
    });
    let tmpList = Object.assign({}, list);
    let prevList = tmpList[room];
    tmpList[room].moodboard = newList;
    setStateAndStore(room, 'remove product', tmpList, prevList);
  };

  const onChange = (newList, room) => {
    let tmpList = Object.assign({}, list);
    let prevList = tmpList[room];
    tmpList[room].moodboard = newList;
    setStateAndStore(room, 'On change', tmpList, prevList);
  };

  const handleSave = async (finalized) => {
    return new Promise(async (resolve, reject) => {
      if (list) {
        let data = {
          quoteID: props.project.quoteID.quoteID,
          title: props.project.project.title,
          moodboardData: props.moodboardData,
          finalized: finalized,
          user: props.user,
        };
        let response = await fetch(
          `${FETCH_URL}/moodboard/${props.project.quoteID.quoteID}`,
          {
            method: 'PATCH',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify(data),
          }
        );

        let responseBody = await response.text();
        let payload = JSON.parse(responseBody);

        if (payload.success) {
          alert.show(payload.message);
          resolve(payload);
        } else {
          alert.show(payload.message);
          resolve(payload);
        }
      } else {
        alert.show('Moodboard Empty');
        resolve();
      }
    });
  };

  const getImageDimensions = (url) => {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.addEventListener('load', function () {
        resolve({
          width: this.naturalWidth,
          height: this.naturalHeight,
        });
      });
      img.src = url;
    });
  };

  const removeBG = async (item, room) => {
    let tmpList = Object.assign({}, list);
    let prevList = tmpList[room];
    const index = tmpList[room].moodboard.findIndex(
      (i) => i.moodboardId === item.moodboardId
    );
    let tmpImg = tmpList[room].moodboard[index].displayedImage;
    tmpList[room].moodboard[index].displayedImage = loadingImg;
    let tmpImgDims = tmpList[room].moodboard[index].moodboardMeta.dimensions;
    tmpList[room].moodboard[index].moodboardMeta.dimensions = {
      width: 200,
      height: 200,
    };
    const imgIndex = tmpList[room].moodboard[index].imageURLs.findIndex(
      (url) => url === tmpImg
    );

    setStateAndStore(room, 'remove bg', { ...tmpList }, prevList);

    let response = await fetch(`${FETCH_URL}/removebg`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        imageURL: tmpImg,
        imgIndex: imgIndex,
        imgArray: tmpList[room].moodboard[index].imageURLs,
        vendorName: item.vendorName,
        SKU: item.SKU,
      }),
    });

    let responseBody = await response.text();
    let payload = JSON.parse(responseBody);

    if (payload.success) {
      tmpList[room].moodboard[index].displayedImage = payload.newURL;
      tmpList[room].moodboard[index].moodboardMeta.dimensions = tmpImgDims;

      setStateAndStore(room, 'on change', { ...tmpList }, prevList);
      alert.show(payload.message);
    } else {
      tmpList[room].moodboard[index].displayedImage = tmpImg;
      setStateAndStore(room, 'on change', { ...tmpList }, prevList);
      alert.show(payload.message);
    }
  };

  const handleAddRoom = (evt) => {
    let id = Date.now();
    let tmp = canvasArr;
    tmp.push(id);
    let zoomEnabledTmp = Object.assign({}, zoomEnabled);
    zoomEnabledTmp[id] = true;
    setCanvasArr([...tmp]);
    setZoomEnabled(zoomEnabledTmp);
  };

  const handleRemoveRoom = (canvasID) => {
    let tmp = canvasArr.filter((canvas) => canvas !== canvasID);
    setCanvasArr([...tmp]);
  };

  const setBackground = (hex, roomType) => {
    let tmp = Object.assign({}, list);
    let prevList = tmp[roomType];
    tmp[roomType].meta = { background: hex };
    setStateAndStore(roomType, 'Set background color', tmp, prevList);
  };

  const handleZoomLock = (canvasID) => {
    let zoomEnabledTmp = Object.assign({}, zoomEnabled);
    zoomEnabledTmp[canvasID] = !zoomEnabled[canvasID];
    setZoomEnabled(zoomEnabledTmp);
  };

  const handleGridView = () => {
    props.dispatch({
      type: 'set-moodboardData',
      value: list,
      unit: props.unit.unit.fields['Item Name'],
    });
    props.history.push('/gridView');
  };

  const clearCanvas = (roomType) => {
    let newList = Object.assign({}, list);
    let prevList = list[roomType];
    newList[roomType] = {
      moodboard: [],
      meta: {},
      text: {},
    };
    setStateAndStore(roomType, 'Clear Canvas', newList, prevList);
  };

  const finalizeMoodboard = async () => {
    setAirtableLoading(true);
    let finalized = await getAirtableData();

    if (!finalized) {
      let data = {
        quoteID: props.project.quoteID.quoteID,
        projectTitle: props.project.project.title,
        moodboardData: props.moodboardData,
        projectCurrency: props.project.projectCurrency,
        user: props.user,
      };

      let response = await fetch(`${FETCH_URL}/airtable/moodboard/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
      });

      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);

      if (payload.success) {
        alert.show(payload.message);
        sendQuoteDataWithImgs();
        sendProjectInfoToAirtable();
        setAirtableLoading(false);
        setShowClientLinkModal(true);
        clientLink && window.open(clientLink, '_blank');
      } else {
        alert.show(payload.message);
        setAirtableLoading(false);
      }
    } else {
      handleModalClose('overwriteModal');
    }
  };

  const copyOfArrayOrObject = (array) => {
    return JSON.parse(JSON.stringify(array));
  };

  const getMoodBoardItemImages = (unitName, roomName, itemName) => {
    let itemData = [];
    if (
      props.moodboardData[unitName] &&
      props.moodboardData[unitName][roomName]
    ) {
      const roomMoodBoardItems =
        props.moodboardData[unitName][roomName].moodboard;
      roomMoodBoardItems.forEach((item, index) => {
        if (
          item.fulhausCategory === itemName ||
          item.mappedFulhausCategory === itemName
        ) {
          let imageUrls = [];
          imageUrls.push(item.displayedImage);
          itemData.push({
            urls: imageUrls,
            priceMAP: item.MAP,
            imageName: item.itemName,
          });
        }
      });
    }
    return itemData;
  };

  const getUpdatedQuoteDataWithMoodboardImages = () => {
    const copyOfQuoteData = copyOfArrayOrObject(props.project.quoteData);
    copyOfQuoteData.forEach((quoteData) => {
      quoteData.quoteItems.forEach((quoteItem) => {
        quoteItem.items.forEach((item) => {
          item.images = getMoodBoardItemImages(
            quoteData.unit.fields['Item Name'],
            quoteItem.room.description,
            item.fields['Item Name']
          );
        });
      });
    });
    return copyOfQuoteData;
  };

  const sendQuoteDataWithImgs = async () => {
    const updatedQuoteDataWithMoodboardImages = getUpdatedQuoteDataWithMoodboardImages();
    const quoteIDObject = props.project.quoteID;

    let data = {
      quoteIDObject: quoteIDObject,
      quoteData: updatedQuoteDataWithMoodboardImages,
    };
    let response = await fetch(`${FETCH_URL}/quotes/update-quoteData`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    });

    let responseBody = await response.text();
    let payload = JSON.parse(responseBody);
    if (payload.success) {
      setClientLink(payload.data.quoteLink);
    }
  };

  const sendProjectInfoToAirtable = async () => {
    let params = {
      'Listing ID': props.project.project.title,
      'Project Address': props.project.project.org_id.address
        ? props.project.project.org_id.address
        : '',
      'Quote ID': props.project.quoteID.quoteID,
      Creator: props.project.project.creator_user_id.name,
      Currency: props.project.project.currency,
      'Start Date': props.project.project.add_time,
      'Client Docs': props.projectFiles,
    };
    let response = await fetch(`${FETCH_URL}/airtable/projects/`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params),
    });

    let responseBody = await response.text();
    let payload = JSON.parse(responseBody);

    if (payload.success) {
      console.log('Project data ', payload);
    } else {
      console.log('Project data ', payload);
    }
  };

  const handleModalClose = (type) => {
    if (type === 'overwriteModal') {
      setShowOverwriteModal(!showOverwriteModal);
      setAirtableLoading(false);
    }
    if (type === 'clientLinkModal') {
      setShowClientLinkModal(!showClientLinkModal);
    }
  };

  const overwriteAirtable = async () => {
    setAirtableLoading(true);
    handleModalClose('overwriteModal');
    let projectTitle = props.project.project.title;
    let quoteID = props.project.quoteID.quoteID;
    let data = {
      quoteID: quoteID,
      projectTitle: projectTitle,
      moodboardData: props.moodboardData,
      projectCurrency: props.project.projectCurrency,
    };
    let response = await fetch(`${FETCH_URL}/airtable/moodboard/${quoteID}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    });
    let responseBody = await response.text();
    let payload = JSON.parse(responseBody);
    if (payload.success) {
      finalizeMoodboard();
      setAirtableLoading(false);
    } else {
      alert.show('ERROR');
      setAirtableLoading(false);
    }
  };

  const getAirtableData = () => {
    let quoteID = props.project.quoteID.quoteID;
    let projectCurrency = props.project.projectCurrency;

    let params = {
      quoteID: quoteID,
      projectCurrency: projectCurrency,
    };

    return new Promise(async (resolve, reject) => {
      let response = await fetch(
        `${FETCH_URL}/airtable/moodboard/${JSON.stringify(params)}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);
      if (payload.success) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const handleStateHistory = (roomType, stateId, historyData) => {
    let tmpList = Object.assign({}, list);
    tmpList[roomType] = historyData.prevState;
    console.log('SETHISTORY', roomType, 'History', historyData);
    setStateAndStore(roomType, 'History', tmpList, list);
  };

  return (
    <>
      <div className='moodboard-wrapper'>
        <div className='moodboard-topBar'>
          <div>
            <button
              style={{
                background: 'rgba(116, 138, 161, 0.9)',
                border: 'none',
                borderRadius: '5px',
                padding: '6px 6px',
                marginLeft: '5px',
              }}
              onClick={() => {
                handleGridView();
              }}
            >
              <FiGrid />
            </button>
          </div>
          <div className='moodboard-topBar-buttons'>
            <Button
              background='rgba(116, 138, 161, 0.9)'
              borderRadius='20px'
              width='40%'
              height='60%'
              fontSize='10px'
              margin='0'
              padding='8px 12px'
              enabled={true}
              onClick={() => handleSave(false)}
              color='white'
              label='Save'
            />
            <Button
              background='rgba(116, 138, 161, 0.9)'
              borderRadius='20px'
              width='40%'
              height='60%'
              fontSize='10px'
              margin='0'
              padding='8px 12px'
              enabled={true}
              onClick={(evt) => handleAddRoom(evt)}
              color='white'
              label='Add'
            />
            <button
              style={{
                background: 'rgba(116, 138, 161, 0.9)',
                borderRadius: '20px',
                width: '180px',
                height: '60%',
                fontSize: '10px',
                fontWeight: 'bold',
                margin: '0',
                padding: airtableLoading ? '0' : '8px 12px',
                border: 'none',
                color: 'white',
              }}
              onClick={(evt) => finalizeMoodboard()}
            >
              {airtableLoading ? <CircularProgress size={25} /> : <>Finalize</>}
            </button>
          </div>
        </div>
        <div
          id='moodboard-canvas'
          ref={ref}
          className='moodboard-canvas'
          style={{
            backgroundColor: 'white',
          }}
        >
          {list &&
            canvasArr.map((canvasId) => (
              <div
                style={{
                  width: '895px',
                  height: '640px',
                  position: 'relative',
                }}
              >
                <Canvas
                  handleStateHistory={(roomType, id, data) =>
                    handleStateHistory(roomType, id, data)
                  }
                  historyList={listHistory}
                  projectCurrency={props.project.projectCurrency}
                  key={canvasId}
                  canvasId={canvasId}
                  handleZoomLock={handleZoomLock}
                  zoomLockList={zoomEnabled}
                  project={props.project}
                  addSourcedItem={(item, roomType) =>
                    addSourcedItem(item, roomType)
                  }
                  onRemove={onRemove}
                  onChange={onChange}
                  list={list}
                  removeBG={removeBG}
                  setBackground={setBackground}
                  handleRemoveRoom={(canvasId) => handleRemoveRoom(canvasId)}
                  clearCanvas={clearCanvas}
                />
              </div>
            ))}
        </div>
      </div>
      {showOverwriteModal && (
        <OverwriteModal
          handleModalClose={() => handleModalClose('overwriteModal')}
          overwriteAirtable={() => overwriteAirtable()}
        />
      )}
      {showClientLinkModal && clientLink && props.project && (
        <ClientLinkModal
          handleModalClose={() => {
            handleModalClose('clientLinkModal');
          }}
          link={`${clientLink}`}
        />
      )}
    </>
  );
};
let mapStateToProps = (state) => {
  return {
    exchangeRate: state.exchangeRate,
    user: state.user,
    projectFiles: state.projectFiles,
    project: state.project,
    unit: state.unit,
    moodboardData: state.moodboardData,
  };
};
let MoodBoard = connect(mapStateToProps)(UnconnectedMoodBoard);
export default withRouter(MoodBoard);
