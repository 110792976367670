import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GridCanvas from './GridCanvas';
import GridFooter from './GridFooter';
import { FaFileDownload } from 'react-icons/fa';
import { PDFExport } from '@progress/kendo-react-pdf';
import '../css/gridView.css';

const ref = React.createRef();

const UnconnectedGridViewContainer = (props) => {
  useEffect(() => {
    if (!props.unit) {
      props.history.push('/');
    }
  }, []);

  const generatePdf = () => {
    ref.current.save();
  };

  return (
    <>
      {props.unit && props.moodboardData && props.project ? (
        <>
          <PDFExport
            ref={ref}
            paperSize='auto'
            fileName={`Moodboard_-_${
              props.project && props.project.project.title
            }_-_${props.unit && props.unit.unit.fields['Item Name']}`}
            author='Fulhaus'
          >
            <div className='gridView-top-banner'>
              <button
                style={{
                  background: 'rgba(116, 138, 161, 0.9)',
                  border: 'none',
                  borderRadius: '5px',
                  padding: '6px 6px',
                }}
                onClick={() => generatePdf()}
              >
                <FaFileDownload style={{ fontSize: '1.8em' }} />
              </button>
              <h3>{props.unit && props.unit.unit.fields['Item Name']}</h3>
              {/* {props.project && <NavDropDown units={props.project.quoteData} />} */}
            </div>

            <div className='gridView-container'>
              {props.moodboardData &&
                props.unit &&
                Object.keys(
                  props.moodboardData[props.unit.unit.fields['Item Name']]
                ).map((room, index) => {
                  return (
                    props.moodboardData[props.unit.unit.fields['Item Name']][
                      room
                    ].moodboard.length > 0 && (
                      <GridCanvas
                        list={
                          props.moodboardData[
                            props.unit.unit.fields['Item Name']
                          ][room]
                        }
                        roomType={room}
                      />
                    )
                  );
                })}
            </div>
          </PDFExport>
          <GridFooter />
        </>
      ) : (
        <div>ERROR</div>
      )}
    </>
  );
};

let mapStateToProps = (state) => {
  return {
    moodboardData: state.moodboardData,
    project: state.project,
    unit: state.unit,
  };
};
let GridViewContainer = connect(mapStateToProps)(UnconnectedGridViewContainer);

export default withRouter(GridViewContainer);
