import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import InputBox from '../../reusbleComponents/InputBox';
import Button from '../../reusbleComponents/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { CircularProgress } from '@material-ui/core';
import { FaFileImage } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(92,96,118,.75)',
    boxShadow: 'none',
  },
  paper: {
    width: '500px',
    backgroundColor: '#748aa1',
    boxShadow: 'none',
    border: '0',
    padding: theme.spacing(2, 4, 3),
  },
}));

const UnconnectedBackgroundUploadModal = (props) => {
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    title: '',
    description: '',
  });
  const [imageUpload, setImageUplaod] = useState({
    file: null,
    preview: null,
  });

  const handleFile = (event) => {
    event.preventDefault();
    setImageUplaod({
      file: event.target.files[0],
      preview: URL.createObjectURL(event.target.files[0]),
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={true}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <div className='backgroundUploadModal-container'>
            <div className='landingPage-form'>
              <h1>Upload Image</h1>

              {/* <InputBox
                background=" #FFFFFF"
                border=" 1px solid #B8C5D3"
                borderRadius=" 40px"
                width="100%"
                hieght="50px"
                placeholder="Enter title"
                onChange={evt => {
                  setInputs({
                    ...inputs,
                    title: evt.target.value
                  });
                }}
              /> */}
              <InputBox
                background=' #FFFFFF'
                border=' 1px solid #B8C5D3'
                borderRadius=' 40px'
                width='100%'
                hieght='50px'
                placeholder='Enter Image description'
                onChange={(evt) => {
                  setInputs({
                    ...inputs,
                    description: evt.target.value,
                  });
                }}
              />

              <input
                className='backgroundmodal-upload-button '
                type='file'
                onChange={(evt) => handleFile(evt)}
              />
              <div className='backgroundmodal-preview'>
                {imageUpload.file ? (
                  <img
                    alt='preview-img'
                    className='backgroundmodal-uploadPreview'
                    width='100px'
                    src={imageUpload.preview}
                  />
                ) : (
                  <FaFileImage
                    style={{
                      color: 'white',
                      fontSize: '50px',
                    }}
                  />
                )}
              </div>
              <div className='landingPage-button-container'>
                <Button
                  background='#748AA1'
                  borderRadius='100px'
                  width='50%'
                  enabled={true}
                  onClick={props.handleClose}
                  color='white'
                  label='Cancel'
                />
                <Button
                  background='#FF4B30'
                  borderRadius='100px'
                  width='50%'
                  enabled={true}
                  onClick={() => {
                    props.handleSubmit(imageUpload, inputs);
                  }}
                  color='white'
                  label='Submit'
                />
              </div>
              {props.loading ? <CircularProgress /> : ''}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    project: state.project,
  };
};
let BackgroundUploadModal = connect(mapStateToProps)(
  UnconnectedBackgroundUploadModal
);

export default BackgroundUploadModal;
