import React from 'react';
import '../../css/propsCard.css';
import { useDrag } from 'react-dnd';
import { FaTrash } from 'react-icons/fa';

const PropsCard = (props) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'product-card',
      product: props.item,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className='props-card'
      style={{ opacity: isDragging ? '0.5' : '1' }}
    >
      <div>
        <p>{props.item.description}</p>

        <img width='90px' src={props.item.bkgImageURL} alt='Avatar' />
        <div className='props-card-delete'>
          <FaTrash onClick={() => props.removeProp(props.item._id)} />
        </div>
      </div>
    </div>
  );
};

export default PropsCard;
