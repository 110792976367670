import React from 'react';

const DropDownRooms = (props) => {
  return (
    <select
      style={{
        backgroundColor: 'white',
        color: 'black',
      }}
      className='canvas-dropDown'
      onChange={(evt) => props.dropDownChange(evt)}
    >
      {Object.keys(props.list).map((key, index) => {
        return (
          <option
            key={index}
            style={{
              backgroundColor: 'white',
              color: 'black',
            }}
            value={key}
          >
            {key}
          </option>
        );
      })}
    </select>
  );
};

export default DropDownRooms;
