import React, { useEffect, useState } from 'react';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { FETCH_URL } from '../../constants';

//used to convert all images on canvas to BASE64
//images must be in base64 format to be able to download to pdf
export default function ImageToBase64(props) {
  const [imgSrc, setImgSrc] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getBase64 = async () => {
      let base64 = await imageToBase64(props.url);
      setImgSrc(base64);
      setLoading(false);
    };
    getBase64();
  }, [props.url]);

  const imageToBase64 = async (url) => {
    return new Promise(async (resolve, reject) => {
      let response = await fetch(
        `${FETCH_URL}/imageToBase64?url=${JSON.stringify(url)}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );

      let responseBody = await response.text();
      let payload = JSON.parse(responseBody);

      if (payload.success) {
        resolve(payload.imageBase64);
      } else {
        resolve(null);
      }
    });
  };

  return (
    <>
      <div className='overlay-image'>
        {!loading && imgSrc ? (
          <img
            className='image'
            style={{
              ...props.style,
              transform: `rotate(${props.item.moodboardMeta.rotation}deg) ${
                props.item.moodboardMeta.flipImg ? `scaleX(-1)` : ''
              }`,
            }}
            src={imgSrc}
            draggable='false'
            alt=''
          />
        ) : (
          <img
            className='image'
            style={{
              ...props.style,
              transform: `rotate(${props.item.moodboardMeta.rotation}deg) ${
                props.item.moodboardMeta.flipImg ? `scaleX(-1)` : ''
              }`,
            }}
            src={props.url}
            draggable='false'
            alt=''
          />
        )}
      </div>
    </>
  );
}
