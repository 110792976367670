import React, { useState, useEffect } from 'react';
import InputBox from '../../reusbleComponents/InputBox';
import ProductCard from './ProductCard';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orderBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FETCH_URL } from '../../constants';
import Slider from '@material-ui/core/Slider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import AirtableProducts from './airtable/AirtableProducts';
import '../css/productContainer.css';
import DocumentsContainer from './pipedrive/DocumentsContainer';
import DesignStylesContainer from './DesignStyles/DesignStylesContainer';
import BackgroundPropsContainer from './BackgroundProps/BackgroundPropsContainer';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  tabs: {
    fontSize: '10px',
    marginBottom: '10px',
    padding: '0',
  },
  tab: {
    paddingTop: '10px 10px',
    margin: '0',
    fontSize: '13px',
    minWidth: 150,
    width: 10,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '12ch',
    },
  },
}));

function valuetext(value) {
  return `$${value}`;
}

const UnconnectedProductContainer = (props) => {
  const classes = useStyles();
  const [products, setProducts] = useState(null);
  const [sliderValue, setSliderValue] = React.useState([0, 0]);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [marks, setMarks] = useState([]);
  const [sliderMax, setSliderMax] = useState(0);
  const [loading, setLoading] = useState(false);
  const [radius, setRadius] = useState(800);
  const [productsFlag, setProductsFlag] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    weight: 0,
    length: 0,
  });

  useEffect(() => {
    setRadius(800);
    productUpdate(800);
    setTabValue(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.category]);

  useEffect(() => {
    handleProducts(props.category, radius);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions]);

  useEffect(() => {
    productUpdate(radius);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radius]);

  const productUpdate = async (_radius) => {
    if (!props.project) {
      props.history.push('/');
    } else {
      let productRange = await handleProducts(props.category, _radius);
      setFilteredProducts(null);
      setSliderValue([0, productRange.max]);
      setMarks([
        {
          value: 0,
          label: '$0',
        },
        {
          value:
            props.categoryData['Sale Price (USD)'] > productRange.max
              ? ''
              : props.categoryData['Sale Price (USD)'],
          label:
            props.categoryData['Sale Price (USD)'] > productRange.max ? (
              ''
            ) : (
              <div
                style={{
                  marginTop: '-30px',
                  zIndex: '1000',
                }}
              >
                {props.categoryData['Sale Price (USD)'].toFixed(2)}
              </div>
            ),
        },
        {
          value: productRange.max,

          label: `$${productRange.max.toFixed(2)}`,
        },
      ]);

      setSliderMax(productRange.max);
    }
  };

  const handleProducts = async (category, radiusParam) => {
    return new Promise(async (resolve, reject) => {
      let params = {
        priceType: 'MAP',
        radius: radiusParam,
        projectAddress: props.project.project['Project Address'],
        width: dimensions.width,
        height: dimensions.height,
        length: dimensions.length,
        weight: dimensions.weight,
      };

      if (category) {
        setLoading(true);
        let response = await fetch(
          `${FETCH_URL}/items/${category}?params=${JSON.stringify(params)}`,
          {
            method: 'GET',
            credentials: 'include',
          }
        );

        let responseBody = await response.text();
        let payload = JSON.parse(responseBody);
        if (payload.success && payload.items) {
          console.log('test', payload.items.filteredItems);
          radius === 800 && setProductsFlag(true);
          setLoading(false);
          setProducts(payload.items.filteredItems);
          resolve({
            min: payload.items.lowestPrice,
            max: payload.items.highestPrice,
          });
        } else {
          radius === 800 && setProductsFlag(false);
          setLoading(false);
          setProducts(null);
          resolve({
            min: 0,
            max: 0,
          });
        }
      }
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const searchHandler = (evt) => {
    let search = '';
    search = evt.toUpperCase();
    if (search !== '' && products) {
      let searchProducts = products.filter((item) => {
        if (search === 'EAST') {
          return item.vendorRegion.east;
        }
        if (search === 'WEST') {
          return item.vendorRegion.west;
        }
        return (
          item.itemName.toUpperCase().includes(search) ||
          item.vendorName.toUpperCase().includes(search) ||
          item.color.toUpperCase().includes(search) ||
          item.vendorProductName.toUpperCase().includes(search) ||
          item.vendorItemDescription.toUpperCase().includes(search)
        );
      });
      setFilteredProducts(searchProducts);
      setLoading(false);
    } else {
      setFilteredProducts(null);
      setLoading(false);
    }
  };

  const getSearchDebounced = debounce(searchHandler, 1000);

  const handleSliderChange = (event, newValue) => {
    if (newValue[1] <= sliderMax && products && props.rate) {
      let searchProducts = products.filter((item) => {
        return (
          item.MAP * props.rate >= newValue[0] &&
          item.MAP * props.rate <= newValue[1]
        );
      });
      setFilteredProducts(searchProducts);
    } else {
      setFilteredProducts(null);
    }
    setLoading(false);
  };

  const handleDistanceSliderChange = (event, newValue) => {
    setRadius(newValue);
  };

  const getPriceSliderDebounced = debounce(handleSliderChange, 2000);

  return (
    <>
      <div className='product-container'>
        <div className='product-top-menu'>
          <div className='product-top-tabs'>
            <Tabs
              className={classes.tabs}
              value={tabValue}
              indicatorColor='primary'
              variant='scrollable'
              onChange={handleTabChange}
              aria-label='disabled tabs example'
            >
              <Tab className={classes.tab} label='Design Styles' />
              <Tab
                className={classes.tab}
                label={props.category === '' ? 'Category is' : props.category}
              />
              <Tab className={classes.tab} label='Backgrounds' />
              <Tab className={classes.tab} label='Airtable' />
              <Tab className={classes.tab} label='Docs' />
            </Tabs>
          </div>
        </div>
        <div className='product-search-container'>
          {tabValue === 1 && (
            <>
              {products && (
                <InputBox
                  background=' #FFFFFF'
                  border=' 1px solid #B8C5D3'
                  borderRadius=' 40px'
                  width='100%'
                  height='30px'
                  fontSize='12px'
                  padding='5px 10px'
                  placeholder='Search'
                  onChange={(evt) => {
                    setLoading(true);
                    getSearchDebounced(evt.target.value);
                  }}
                />
              )}
              {products && (
                <form className={classes.root} noValidate autoComplete='off'>
                  <TextField
                    id='width'
                    label='Width'
                    type='number'
                    variant='filled'
                    onChange={(evt) => {
                      setDimensions({
                        ...dimensions,
                        width: parseInt(evt.target.value),
                      });
                    }}
                  />
                  <TextField
                    id='length'
                    label='Length'
                    type='number'
                    variant='filled'
                    onChange={(evt) => {
                      setDimensions({
                        ...dimensions,
                        length: parseInt(evt.target.value),
                      });
                    }}
                  />
                  <TextField
                    id='height'
                    label='Height'
                    type='number'
                    variant='filled'
                    onChange={(evt) => {
                      setDimensions({
                        ...dimensions,
                        height: parseInt(evt.target.value),
                      });
                    }}
                  />
                  <TextField
                    id='weight'
                    label='Weight'
                    type='number'
                    variant='filled'
                    onChange={(evt) => {
                      setDimensions({
                        ...dimensions,
                        weight: parseInt(evt.target.value),
                      });
                    }}
                  />
                </form>
              )}
              <>
                {products && (
                  <div>
                    <Typography id='range-slider' gutterBottom>
                      Price range
                    </Typography>
                    <Slider
                      value={sliderValue}
                      onChange={(evt, newValue) => {
                        setLoading(true);
                        setSliderValue(newValue);
                        getPriceSliderDebounced(evt, newValue);
                      }}
                      valueLabelDisplay='auto'
                      aria-labelledby='range-slider'
                      getAriaValueText={valuetext}
                      min={0}
                      max={sliderMax}
                      marks={marks}
                    />
                  </div>
                )}
                {productsFlag && (
                  <div>
                    <Typography id='range-slider' gutterBottom>
                      {`Radius ${radius}miles`}
                    </Typography>
                    <Slider
                      value={radius}
                      defaultValue={800}
                      getAriaValueText={valuetext}
                      onChange={handleDistanceSliderChange}
                      aria-labelledby='discrete-slider'
                      valueLabelDisplay='auto'
                      marks
                      step={50}
                      min={0}
                      max={800}
                    />
                  </div>
                )}
              </>
            </>
          )}
          <div className='product-item-count'>
            {tabValue === 1 &&
              products &&
              filteredProducts === null &&
              `Items: ${products.length}`}
            {tabValue === 1 &&
              filteredProducts !== null &&
              `Items: ${filteredProducts.length}`}
          </div>
        </div>
        <div
          style={{
            height: '80%',
            position: 'relative',
            overflowY: 'scroll',
          }}
        >
          <div className='product-card-container'>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {tabValue === 1 &&
                  products &&
                  filteredProducts === null &&
                  orderBy(products, ['MAP'], ['asc']).map((item) => {
                    return (
                      <ProductCard
                        key={item._id}
                        item={item}
                        exchangeRate={props.rate}
                        projectCurrency={props.project.projectCurrency}
                      />
                    );
                  })}
                {tabValue === 1 &&
                  filteredProducts !== null &&
                  orderBy(filteredProducts, ['MAP'], ['asc']).map((item) => {
                    return (
                      <ProductCard
                        key={item._id}
                        item={item}
                        exchangeRate={props.rate}
                        projectCurrency={props.project.projectCurrency}
                      />
                    );
                  })}
                {tabValue === 1 && !products && (
                  <div className='product-card-container-default-text'>
                    <h3>
                      {props.category === '' ? (
                        <>Choose a category</>
                      ) : (
                        <>No items found for {props.category}</>
                      )}
                    </h3>
                  </div>
                )}
                {tabValue === 2 && <BackgroundPropsContainer />}
                {tabValue === 3 && <AirtableProducts />}
                {tabValue === 4 && <DocumentsContainer />}
                {tabValue === 0 && <DesignStylesContainer />}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

let mapStateToProps = (state) => {
  return {
    project: state.project,
    rate: state.exchangeRate,
  };
};
let ProductContainer = connect(mapStateToProps)(UnconnectedProductContainer);

export default withRouter(ProductContainer);
