import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider as ReduxProvider } from 'react-redux';
import store from './store.js';
import {
  transitions,
  positions,
  Provider as AlertProvider,
} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { Beforeunload } from 'react-beforeunload';

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
};

ReactDOM.render(
  <Beforeunload
    onBeforeunload={() => "You'll lose your data!"}
  >
    <ReduxProvider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
    </ReduxProvider>
  </Beforeunload>,
  document.getElementById('root')
);
